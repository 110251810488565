import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { Box, Button, FormControlLabel, Switch } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import VenueDeliveryConfigFormProps from 'types/forms/VenueDeliveryConfigFormProps';
import VenueDeliveryConfig from 'types/models/VenueDeliveryConfig';
import VenueDeliveryConfigForm from './schemas/VenueDeliveryConfigForm';
import ConfirmDialog from 'components/ConfirmDialog';
import AreasInput from 'components/AreasInput';
import { updateVenueDeliveryConfig } from 'services/venuesServices';
import 'styles/forms/VenueDeliveryConfigForm.scss';

export default ({ venue }: VenueDeliveryConfigFormProps): JSX.Element => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const { t } = useTranslation();

  const onConfirm = async (): Promise<void> => {
    if (!venue) return;

    const dataVenueDeliveryConfig: VenueDeliveryConfig = {
      id: '',
      deliveryEnabled: formik.values.deliveryEnabled,
      pickUpEnabled: formik.values.pickUpEnabled,
      isLocalDelivery: formik.values.isLocalDelivery,
      priorityOverCommerce: formik.values.priorityOverCommerce,
      showPhoneField: formik.values.showPhoneField,
      isPhoneFieldRequired: formik.values.isPhoneFieldRequired,
      areas: formik.values.areas,
    };

    try {
      const result = await updateVenueDeliveryConfig(venue.id, dataVenueDeliveryConfig);
      if (result) {
        history.go(-1);
      } else {
        throw new Error();
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      deliveryEnabled: venue.deliveryConfig?.deliveryEnabled ?? false,
      pickUpEnabled: venue.deliveryConfig?.pickUpEnabled ?? false,
      isLocalDelivery: venue.deliveryConfig?.isLocalDelivery ?? false,
      priorityOverCommerce: venue?.deliveryConfig?.priorityOverCommerce ?? false,
      showPhoneField: venue?.deliveryConfig?.showPhoneField ?? false,
      isPhoneFieldRequired: venue?.deliveryConfig?.isPhoneFieldRequired ?? false,
      areas: venue?.deliveryConfig?.areas ?? [],
    },
    validationSchema: VenueDeliveryConfigForm(t),
    onSubmit: (): void => {
      setOpenDialog(true);
    },
  });

  return (
    <>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <FormControlLabel
            id="deliveryEnabled"
            name="deliveryEnabled"
            control={<Switch checked={formik.values.deliveryEnabled} onChange={formik.handleChange} />}
            label={t('venueDeliveryConfig-form-deliveryEnabled')}
            disabled={formik.isSubmitting}
          />
        </Box>
        <Box>
          <FormControlLabel
            id="pickUpEnabled"
            name="pickUpEnabled"
            control={<Switch checked={formik.values.pickUpEnabled} onChange={formik.handleChange} />}
            label={t('venueDeliveryConfig-form-pickUpEnabled')}
            disabled={formik.isSubmitting}
          />
        </Box>
        <Box>
          <FormControlLabel
            id="isLocalDelivery"
            name="isLocalDelivery"
            control={<Switch checked={formik.values.isLocalDelivery} onChange={formik.handleChange} />}
            label={t('venueDeliveryConfig-form-isLocalDelivery')}
            disabled={formik.isSubmitting}
          />
        </Box>
        <Box>
          <FormControlLabel
            id="priorityOverCommerce"
            name="priorityOverCommerce"
            control={<Switch checked={formik.values.priorityOverCommerce} onChange={formik.handleChange} />}
            label={t('venueDeliveryConfig-form-priorityOverCommerce')}
            disabled={formik.isSubmitting}
          />
        </Box>
        {formik.values.deliveryEnabled && (
          <Box>
            <FormControlLabel
              id="showPhoneField"
              name="showPhoneField"
              control={<Switch checked={formik.values.showPhoneField} onChange={formik.handleChange} />}
              label={t('venueDeliveryConfig-form-showPhoneField')}
              disabled={formik.isSubmitting}
            />
            {formik.values.showPhoneField && (
              <FormControlLabel
                id="isPhoneFieldRequired"
                name="isPhoneFieldRequired"
                control={<Switch checked={formik.values.isPhoneFieldRequired} onChange={formik.handleChange} />}
                label={t('venueDeliveryConfig-form-isPhoneFieldRequired')}
                disabled={formik.isSubmitting}
              />
            )}
          </Box>
        )}
        <AreasInput
          type={'Venue'}
          name={'areas'}
          values={formik.values.areas}
          setFieldValue={formik.setFieldValue}
          isDisabled={formik.isSubmitting}
        />
        <div className="fields-required-alert">Debe agregar al menos un campo *</div>
        <Box marginTop="1rem">
          <Button fullWidth variant="contained" color="primary" type="submit" disabled={formik.isSubmitting}>
            {t('venueDeliveryConfig-form-saveConfig')}
          </Button>
        </Box>
      </form>
    </>
  );
};
