import { TFunction } from 'react-i18next';
import Order from 'types/models/Order';
import { ReducerAction, ReducerState } from 'types/models/ReducerAction';

export const initialStateForCloverPoints = [{ entityId: '', entityType: '', cloverPoints: [] }];

export const cloverPointsReducer = (state: ReducerState[], action: ReducerAction): ReducerState[] => {
  switch (action.type) {
    case 'new-venue-clover-points':
      if (action.operation === 'add') {
        const { entityId, entityType, cloverPoints } = action.payload;
        const stateWithNewVenueCloverPointsAdded = state.concat({ entityId, entityType, cloverPoints });
        const arrFilteredByNewVenueId = stateWithNewVenueCloverPointsAdded.filter(
          (config) => config.entityId === action.payload.entityId,
        );
        return arrFilteredByNewVenueId;
      } else {
        return initialStateForCloverPoints;
      }
    case 'new-commerce-clover-points':
      if (action.operation === 'add') {
        const currentState = [...state];
        const { entityId, entityType, cloverPoints } = action.payload;
        const currentStateCloverPoints = currentState.reduce((acc, value) => {
          if (value.cloverPoints && value.cloverPoints.length > 0) {
            value.cloverPoints?.forEach((cloverPoint) => {
              acc.push(cloverPoint.id);
            });
          }
          return acc;
        }, [] as Array<string>);
        const filteringPossibleDuplicateCloverPointsOnPayload = cloverPoints?.filter((cloverPoint) => {
          return !currentStateCloverPoints.includes(cloverPoint.id);
        });
        currentState.push({
          entityId,
          entityType,
          cloverPoints: filteringPossibleDuplicateCloverPointsOnPayload,
        });
        currentState.sort((a, b) => (a.entityType === 'commerce' && b.entityType === 'commerce' ? 1 : -1));
        return currentState;
      } else {
        return initialStateForCloverPoints;
      }
    case 'delete-commerce-clover-points-not-present-in-id-list':
      if (action.operation === 'delete') {
        const { selectedIds } = action;
        const filteringNotPresentCommerceIdsOnState = state.filter((entityElement) => {
          return selectedIds.includes(entityElement.entityId);
        });
        return filteringNotPresentCommerceIdsOnState;
      } else {
        return initialStateForCloverPoints;
      }
    case 'cleanup-clover-points':
      return initialStateForCloverPoints;
    default:
      return [...state];
  }
};

export const parsePaymentMethod = (t: TFunction, order: Order): string => {
  if (order.isCash) {
    return t('consumptionsReport-page-paymentMethodTypes.cash');
  } else if (order.fiservQrDataPayment) {
    return t('consumptionsReport-page-paymentMethodTypes.qr');
  } else {
    return t('consumptionsReport-page-paymentMethodTypes.card');
  }
};
