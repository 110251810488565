import * as yup from 'yup';
import { TFunction } from 'i18next';
import { enabledValidation } from 'utils/yup';

export default (t: TFunction): any =>
  yup.object({
    deliveryEnabled: enabledValidation,
    pickUpEnabled: enabledValidation,
    isLocalDelivery: enabledValidation,
    priorityOverCommerce: enabledValidation,
    showPhoneField: enabledValidation,
    isPhoneFieldRequired: yup.mixed().when('showPhoneField', {
      is: true,
      then: enabledValidation,
    }),
    areas: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string(),
          description: yup.string().required(t('schema-venueDeliveryConfig-form-areas-description')),
          fields: yup.array().min(1, t('schema-venueDeliveryConfig-form-areas-fields')),
        }),
      )
      .min(1, t('schema-venueDeliveryConfig-form-areas-min')),
  });
