import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControlLabel, Switch } from '@material-ui/core';
import {
  getProviders,
  getFDConfigs,
  getMPConfigs,
  getMPPointConfigs,
  getCloverPointConfigs,
  getIzipayConfigs,
  getMPCheckoutConfigs,
  getFiservQrConfigs,
} from 'services/paymentsServices';
import { updateCommercePaymentConfig } from 'services/commerceServices';
import SelectField from 'components/SelectField';
import ConfirmDialog from 'components/ConfirmDialog';
import CommercePaymentConfigForm from './schemas/CommercePaymentConfigForm';
import CommercePaymentConfigFormProps from 'types/forms/CommercePaymentConfigFormProps';
import PaymentProvider from 'types/models/PaymentProvider';
import FirstDataConfig from 'types/models/FirstDataConfig';
import MercadoPagoConfig from 'types/models/MercadoPagoConfig';
import MercadoPagoPointConfig from 'types/models/MercadoPagoPointConfig';
import CloverPointConfig from 'types/models/CloverPointConfig';
import IzipayConfig from 'types/models/IzipayConfig';
import MercadoPagoCheckoutConfig from 'types/models/MercadoPagoCheckoutConfig';
import FiservQrSubsidiaryConfig from 'types/models/FiservQrSubsidiaryConfig';

export default ({ commerce }: CommercePaymentConfigFormProps): JSX.Element => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [providerList, setProviderList] = React.useState<PaymentProvider[]>([]);
  const [firstDataList, setFirstDataList] = React.useState<FirstDataConfig[]>([]);
  const [MPList, setMPList] = React.useState<MercadoPagoConfig[]>([]);
  const [mpCheckoutList, setMPCheckoutList] = React.useState<MercadoPagoCheckoutConfig[]>([]);
  const [MPPointList, setMPPointList] = React.useState<MercadoPagoPointConfig[]>([]);
  const [cloverPointConfigList, setCloverPointConfigList] = React.useState<CloverPointConfig[]>([]);
  const [izipayConfigList, setIzipayConfigList] = React.useState<IzipayConfig[]>([]);
  const [fiservQrSubsidiaryConfigList, setFiservQrSubsidiaryConfigList] = React.useState<
    FiservQrSubsidiaryConfig[] | []
  >([]);
  const { t } = useTranslation();

  React.useEffect(() => {
    async function getData() {
      const getFDConfigsResult = await getFDConfigs();
      setFirstDataList(getFDConfigsResult);
      const getMPConfigsResult = await getMPConfigs();
      setMPList(getMPConfigsResult);
      const getMPCheckoutConfigsResult = await getMPCheckoutConfigs();
      setMPCheckoutList(getMPCheckoutConfigsResult);
      const getMPPointConfigsResult = await getMPPointConfigs();
      setMPPointList(getMPPointConfigsResult);
      const getCloverPointConfigsResult = await getCloverPointConfigs();
      if (getCloverPointConfigsResult) setCloverPointConfigList(getCloverPointConfigsResult);
      const getIzipayConfigsResult = await getIzipayConfigs();
      if (getIzipayConfigsResult) setIzipayConfigList(getIzipayConfigsResult);
      const getFiservQrConfigsResult = await getFiservQrConfigs();
      if (getFiservQrConfigsResult) setFiservQrSubsidiaryConfigList(getFiservQrConfigsResult);
      const getProvidersResult = await getProviders('commerce');
      setProviderList(getProvidersResult);
    }
    getData();
  }, []);

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const onConfirm = async (): Promise<void> => {
    if (!commerce) return;
    try {
      const data = await updateCommercePaymentConfig(commerce.id, {
        id: '',
        cashEnabled: formik.values.cashEnabled,
        activeProvider: formik.values.activeProvider ?? undefined,
        firstDataConfigId: formik.values.firstDataConfigId ?? undefined,
        mpConfigId: formik.values.mpConfigId ?? undefined,
        mpCheckoutConfigId: formik.values.mpCheckoutConfigId ?? undefined,
        mpPointConfigId: formik.values.mpPointConfigId ?? undefined,
        cloverPointConfigId: formik.values.cloverPointConfigId ?? undefined,
        izipayConfigId: formik.values.izipayConfigId ?? undefined,
        fiservQrConfigId: formik.values.fiservQrConfigId ?? undefined,
      });
      if (data) {
        history.go(-1);
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      cashEnabled: commerce?.paymentConfig?.cashEnabled ?? false,
      activeProvider: commerce?.paymentConfig?.activeProvider ?? undefined,
      firstDataConfigId: commerce?.paymentConfig?.firstDataConfigId ?? undefined,
      mpConfigId: commerce?.paymentConfig?.mpConfigId ?? undefined,
      mpCheckoutConfigId: commerce?.paymentConfig?.mpCheckoutConfigId ?? undefined,
      mpPointConfigId: commerce?.paymentConfig?.mpPointConfigId ?? undefined,
      cloverPointConfigId: commerce?.paymentConfig?.cloverPointConfigId ?? undefined,
      izipayConfigId: commerce?.paymentConfig?.izipayConfigId ?? undefined,
      fiservQrConfigId: commerce?.paymentConfig?.fiservQrConfigId ?? undefined,
    },
    validationSchema: CommercePaymentConfigForm,
    onSubmit: async () => {
      setOpenDialog(true);
    },
  });

  const providersOptions = providerList.map((provider: PaymentProvider) => ({
    value: provider.id,
    label: provider.description,
  }));

  const firstDataOptions = firstDataList.map((fdItem: FirstDataConfig) => ({
    value: fdItem.id,
    label: fdItem.description,
  }));

  const mpOptions = MPList.map((mpItem: MercadoPagoConfig) => ({
    value: mpItem.id,
    label: mpItem.description,
  }));

  const mpCheckoutOptions = mpCheckoutList.map((mpCheckoutItem: MercadoPagoCheckoutConfig) => ({
    value: mpCheckoutItem.id,
    label: mpCheckoutItem.description,
  }));

  const mpPointOptions = MPPointList.map((mpPointItem: MercadoPagoPointConfig) => ({
    value: mpPointItem.id,
    label: mpPointItem.description,
  }));

  const cloverPointOptions = cloverPointConfigList.map((cloverPointConfigItem: CloverPointConfig) => ({
    value: cloverPointConfigItem.id,
    label: cloverPointConfigItem.description,
  }));

  const izipayOptions = izipayConfigList.map((izipayCongifItem: IzipayConfig) => ({
    value: izipayCongifItem.id,
    label: izipayCongifItem.description,
  }));

  const fiservQrOptions = fiservQrSubsidiaryConfigList.map(
    (fiservQrSubsidiaryConfigItem: FiservQrSubsidiaryConfig) => ({
      value: fiservQrSubsidiaryConfigItem.id,
      label: fiservQrSubsidiaryConfigItem.name,
    }),
  );

  return (
    <>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <FormControlLabel
            id="cashEnabled"
            name="cashEnabled"
            control={<Switch checked={formik.values.cashEnabled} onChange={formik.handleChange} />}
            label={t('commercePaymentConfig-form-cashEnabled')}
            disabled={formik.isSubmitting}
          />
        </Box>
        <SelectField
          name="activeProvider"
          label={t('commercePaymentConfig-form-activeProvider-label')}
          placeholder={t('commercePaymentConfig-form-activeProvider-placeholder')}
          value={formik.values.activeProvider}
          options={providersOptions}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.activeProvider}
          error={formik.errors.activeProvider}
          isClearable={true}
          backspaceRemovesValue={true}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
        <SelectField
          name="firstDataConfigId"
          label={t('commercePaymentConfig-form-firstDataConfigId-label')}
          placeholder={t('commercePaymentConfig-form-firstDataConfigId-placeholder')}
          value={formik.values.firstDataConfigId}
          options={firstDataOptions}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.firstDataConfigId}
          error={formik.errors.firstDataConfigId}
          isClearable={true}
          backspaceRemovesValue={true}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
        <SelectField
          name="mpConfigId"
          label={t('commercePaymentConfig-form-mpConfigId-label')}
          placeholder={t('commercePaymentConfig-form-mpConfigId-placeholder')}
          value={formik.values.mpConfigId}
          options={mpOptions}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.mpConfigId}
          error={formik.errors.mpConfigId}
          isClearable={true}
          backspaceRemovesValue={true}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
        <SelectField
          name="mpCheckoutConfigId"
          label={t('venuePaymentConfig-form-mpCheckoutConfigId-label')}
          placeholder={t('venuePaymentConfig-form-mpCheckoutConfigId-placeholder')}
          value={formik.values.mpCheckoutConfigId}
          options={mpCheckoutOptions}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.mpCheckoutConfigId}
          error={formik.errors.mpCheckoutConfigId}
          isClearable={true}
          backspaceRemovesValue={true}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
        <SelectField
          name="mpPointConfigId"
          label={t('commercePaymentConfig-form-mpPointConfigId-label')}
          placeholder={t('commercePaymentConfig-form-mpPointConfigId-label')}
          value={formik.values.mpPointConfigId}
          options={mpPointOptions}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.mpPointConfigId}
          error={formik.errors.mpPointConfigId}
          isClearable={true}
          backspaceRemovesValue={true}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
        <SelectField
          name="cloverPointConfigId"
          label={t('commercePaymentConfig-form-cloverPointConfigId-label')}
          placeholder={t('commercePaymentConfig-form-cloverPointConfigId-placeholder')}
          value={formik.values.cloverPointConfigId}
          options={cloverPointOptions}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.cloverPointConfigId}
          error={formik.errors.cloverPointConfigId}
          isClearable={true}
          backspaceRemovesValue={true}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
        <SelectField
          name="izipayConfigId"
          label={t('commercePaymentConfig-form-izipayConfigId-label')}
          placeholder={t('commercePaymentConfig-form-izipayConfigId-placeholder')}
          value={formik.values.izipayConfigId}
          options={izipayOptions}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.izipayConfigId}
          error={formik.errors.izipayConfigId}
          isClearable={true}
          backspaceRemovesValue={true}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
        <SelectField
          name="fiservQrConfigId"
          label={t('commercePaymentConfig-form-fiservQrConfigId-label')}
          placeholder={t('commercePaymentConfig-form-fiservQrConfigId-placeholder')}
          value={formik.values.fiservQrConfigId}
          options={fiservQrOptions}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.fiservQrConfigId}
          error={formik.errors.fiservQrConfigId}
          isClearable={true}
          backspaceRemovesValue={true}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
        <Box marginTop="1rem">
          <Button fullWidth variant="contained" color="primary" type="submit" disabled={formik.isSubmitting}>
            {t('commercePaymentConfig-form-save')}
          </Button>
        </Box>
      </form>
    </>
  );
};
