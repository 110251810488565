import * as yup from 'yup';
import { enabledValidation } from 'utils/yup';

export default (): any =>
  yup.object({
    deliveryEnabled: enabledValidation,
    pickUpEnabled: enabledValidation,
    isLocalDelivery: enabledValidation,
    showPhoneField: enabledValidation,
    isPhoneFieldRequired: yup.mixed().when('showPhoneField', {
      is: true,
      then: enabledValidation,
    }),
    areas: yup.array(),
  });
