import { TFunction } from 'react-i18next';
import * as yup from 'yup';

export default (t: TFunction): any =>
  yup.object({
    name: yup.string().required(t('schema-FiservQrSubsidiary-form-name-required')),
    address: yup.string().required(t('schema-FiservQrSubsidiary-form-address-required')),
    phone: yup
      .string()
      .required(t('schema-FiservQrSubsidiary-form-phone-required'))
      .matches(
        /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,
        t('schema-FiservQrSubsidiary-form-phone-matches'),
      ),
    streetNumber: yup.string().required(t('schema-CloverPointConfig-form-description-required')),
    streetName: yup.string().required(t('schema-CloverPointConfig-form-description-required')),
    city: yup.string().required(t('schema-CloverPointConfig-form-description-required')),
    postalCode: yup.string().required(t('schema-CloverPointConfig-form-description-required')),
    department: yup.string().required(t('schema-CloverPointConfig-form-description-required')),
    country: yup.string().required(t('schema-CloverPointConfig-form-description-required')),
    state: yup.string().required(t('schema-CloverPointConfig-form-description-required')),
  });
