import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import SelectField from 'components/SelectField';
import { getAllReportingLoads, getReportingLoads } from 'services/orderServices';
import { getAllVenues } from 'services/venuesServices';
import type BalanceTransaction from 'types/models/BalanceTransaction';
import type PaginationData from 'types/models/PaginationData';
import type Venue from 'types/models/Venue';
import 'styles/pages/ConsumptionsReportPage.scss';
import { parseDateTime, parseDateToFormik, parseDateTimeLumen } from 'utils/datetime';

export default (): JSX.Element => {
  const [params, setParams] = React.useState<Record<any, any> | null>(null);
  const [paginationLoads, setPaginationLoads] = React.useState<PaginationData<BalanceTransaction> | null>(null);
  const { t } = useTranslation();

  const loadData = async (page?: number) => {
    if (!params) return;
    const allLoads = await getReportingLoads(params, page);

    if (allLoads) {
      setPaginationLoads(allLoads);
    }
  };

  const downloadReport = async (): Promise<void> => {
    if (!params) return;
    const result = await getAllReportingLoads(params);
    if (result) {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(
        new Blob([result], {
          type: 'application/octet-stream',
        }),
      );
      a.download = 'reporte_cargas.xlsx';
      a.click();
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  React.useEffect(() => {
    if (paginationLoads) {
      setPaginationLoads(null);
    }
    loadData();
  }, [params]);

  const renderContent = (balance: BalanceTransaction): JSX.Element => {
    return (
      <>
        <TableRow key={balance.id} className="content-table">
          <TableCell>{balance.user.email}</TableCell>
          <TableCell>{balance.user.phone}</TableCell>
          <TableCell>{balance.amount}</TableCell>
          <TableCell>{parseDateTime(balance.localDateTime)}</TableCell>
          <TableCell>{balance.venue.name}</TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <>
      <LoadReportForm parameters={[params, setParams]} />
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>{t('loadsReport-page-email')}</TableCell>
              <TableCell>{t('loadsReport-page-phone')}</TableCell>
              <TableCell>{t('loadsReport-page-amount')}</TableCell>
              <TableCell>{t('loadsReport-page-loadedAt')}</TableCell>
              <TableCell>{t('loadsReport-page-venue')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginationLoads?.data && paginationLoads?.data.length > 0 ? (
              paginationLoads?.data.map((balance: BalanceTransaction) => (
                <React.Fragment key={balance.id}>{renderContent(balance)}</React.Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell>{t('loadsReport-page-noLoads')}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {paginationLoads && (
        <>
          <TablePagination
            component="div"
            count={paginationLoads.total}
            page={paginationLoads.currentPage - 1}
            onPageChange={(_, page: number) => {
              loadData(page + 1);
            }}
            rowsPerPage={paginationLoads.perPage}
            rowsPerPageOptions={[]}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}-${to} ${t('pagination-of')} ${count !== -1 ? count : '0'}`;
            }}
          />
          {paginationLoads?.data.length > 0 && (
            <Box marginTop="1rem">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => {
                  downloadReport();
                }}
              >
                {t('consumptionsReport-page-download')}
              </Button>
            </Box>
          )}
        </>
      )}
    </>
  );
};

const LoadReportForm = ({
  parameters: useParameters,
}: {
  parameters: [Record<any, any> | null, (params: Record<any, any>) => void];
}) => {
  const [params, setParams] = useParameters;
  const [venues, setVenues] = React.useState<Venue[]>([]);
  const [selectedVenueTimeZone, setSelectedVenueTimeZone] = React.useState<string | null>(null);
  const { t } = useTranslation();
  const venuesOptions = (function () {
    return venues.map((venue) => ({
      label: venue.name,
      value: venue.id,
    }));
  })();

  React.useEffect(() => {
    (async () => {
      const allVenues = await getAllVenues();
      if (allVenues) setVenues(allVenues);
    })();
  }, []);

  const formik = useFormik({
    initialValues: {
      dateFrom: '',
      dateTo: '',
      venueId: '',
    },
    onSubmit: (values) => {
      setParams({
        ...params,
        ...values,
        dateFrom: parseDateTimeLumen(moment(values.dateFrom).toDate(), ''),
        dateTo: parseDateTimeLumen(moment(values.dateTo).toDate(), ''),
      });
    },
    validationSchema: yup.object({
      venueId: yup.string().required(t('general-suggestions.select-venue-before-filtering')),
    }),
  });

  React.useEffect(() => {
    const getSelectedVenueTimeZone = (): void => {
      if (!venues || !formik.values.venueId) return;
      const venueTimeZone = venues.find((venue) => venue.id.includes(formik.values.venueId))?.timeZoneIdentifier;
      if (venueTimeZone) setSelectedVenueTimeZone(venueTimeZone);
    };
    getSelectedVenueTimeZone();
  }, [formik.values.venueId]);

  return (
    <form className="Grid--layout">
      <div className="Grid--row">
        <SelectField
          label={t('loadsReport-form-venueId')}
          name="venueId"
          placeholder={t('loadsReport-form-venueId-placeholder')}
          value={formik.values.venueId}
          options={venuesOptions}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.venueId}
          error={formik.errors.venueId}
          isClearable={false}
          backspaceRemovesValue={false}
          isDisabled={false}
          isSearchable={true}
        />
      </div>
      <div className="Grid--row">
        <div className="Grid--columns">
          <TextField
            margin="dense"
            name="dateFrom"
            fullWidth
            label={t('consumptionsReport-form-dateFrom')}
            value={
              formik.values.dateFrom
                ? parseDateToFormik(moment(formik.values.dateFrom).toDate(), '')
                : formik.values.dateFrom
            }
            onChange={(event) => {
              formik.setFieldValue(
                'dateFrom',
                moment(moment(event.target.value).tz(selectedVenueTimeZone ?? '')).toDate(),
              );
            }}
            error={formik.touched.dateFrom && Boolean(formik.errors.dateFrom)}
            helperText={formik.touched.dateFrom && formik.errors.dateFrom}
            type="datetime-local"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            name="dateTo"
            fullWidth
            label={t('consumptionsReport-form-dateTo')}
            value={
              formik.values.dateTo ? parseDateToFormik(moment(formik.values.dateTo).toDate(), '') : formik.values.dateTo
            }
            onChange={(event) => {
              formik.setFieldValue(
                'dateTo',
                moment(moment(event.target.value).tz(selectedVenueTimeZone ?? '')).toDate(),
              );
            }}
            error={formik.touched.dateTo && Boolean(formik.errors.dateTo)}
            helperText={formik.touched.dateTo && formik.errors.dateTo}
            type="datetime-local"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="Grid--row">
          <Box marginTop="1rem">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="button"
              disabled={!formik.isValid || !formik.values.venueId}
              onClick={(e) => {
                e.preventDefault();
                formik.submitForm();
              }}
            >
              {t('consumptionsReport-form-search')}
            </Button>
          </Box>
        </div>
      </div>
    </form>
  );
};
