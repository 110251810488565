import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Button,
  TextField,
  FormControlLabel,
  Switch,
  InputAdornment,
  Typography,
  List,
  ListItem,
} from '@material-ui/core';
import {
  storeVenue,
  updateVenue,
  getApps,
  getAllVenues,
  parseAllVenueSectionTypes,
  parseVenueSectionType,
} from 'services/venuesServices';
import { getLanguages } from 'services/languageServices';
import { getCommerces } from 'services/commerceServices';
import { getUsersByEditorType } from 'services/userServices';
import ImageInput from 'components/ImageInput';
import SelectField from 'components/SelectField';
import ConfirmDialog from 'components/ConfirmDialog';
import VenueFormProps from 'types/forms/VenueFormProps';
import SelectedFieldOption from 'types/models/SelectFieldOption';
import Commerce from 'types/models/Commerce';
import App from 'types/models/App';
import Venue from 'types/models/Venue';
import Language from 'types/models/Language';
import VenueSection from 'types/models/VenueSection';
import User from 'types/models/User';
import AppContext from 'contexts/AppContext';
import VenueImageType from 'types/enums/VenueImageType';
import VenueSectionType from 'types/enums/VenueSectionType';
import UserRoleType from 'types/enums/UserRoleType';
import { CreateForm, EditForm } from './schemas/VenueForm';

export default ({ venue }: VenueFormProps): JSX.Element => {
  const { authData } = React.useContext(AppContext);
  const history = useHistory();
  const { t } = useTranslation();
  const [commerces, setCommerces] = React.useState<Commerce[] | null>(null);
  const [languages, setLanguages] = React.useState<Language[] | null>(null);
  const [venueUsers, setVenueUsers] = React.useState<User[] | null>(null);
  const [errorSameEditor, setErrorSameEditor] = React.useState<string>('');
  const [openDialog, setOpenDialog] = React.useState(false);
  const [apps, setApps] = React.useState<App[] | null>(null);
  const [venueSections, setVenueSections] = React.useState<VenueSection<VenueSectionType>[]>(
    venue?.sections && venue?.sections.length > 0 ? venue?.sections : [],
  );
  const logo = venue?.images?.find((image) => image.type === VenueImageType.Logo);
  const map = venue?.images?.find((image) => image.type === VenueImageType.Map);
  const venueMap = venue?.images?.find((image) => image.type === VenueImageType.VenueMap);
  const downloadBannerImage = venue?.images?.find((image) => image.type === VenueImageType.InstalationAppBanner);
  const banner = venue?.images?.find((image) => image.type === VenueImageType.Banner);
  const cloverMiniBackgroundImage = venue?.images?.find(
    (image) => image.type === VenueImageType.CloverMiniBackgroundImage,
  );
  const cloverMiniLowerBanner = venue?.images?.find((image) => image.type === VenueImageType.CloverMiniLowerBanner);
  const cloverFlexBackgroundImage = venue?.images?.find(
    (image) => image.type === VenueImageType.CloverFlexBackgroundImage,
  );
  const cloverFlexLowerBanner = venue?.images?.find((image) => image.type === VenueImageType.CloverFlexLowerBanner);
  const venueLogoForTinting = venue?.images?.find((image) => image.type === VenueImageType.LogoForTinting);
  const currentVenueSectionOrders = venueSections.map((section) => section.order);
  const uniqueVenueSectionOrders = new Set(currentVenueSectionOrders);
  const hasBettingSection = venueSections.some((section) => section.type === VenueSectionType.Betting);

  React.useEffect(() => {
    const getAllCommerces = async () => {
      const allCommerces = await getCommerces();
      setCommerces(allCommerces);
    };
    getAllCommerces();

    const getAllApps = async () => {
      const allApps = await getApps();
      setApps(allApps);
    };
    getAllApps();

    const getAllLanguages = async () => {
      const allLanguages = await getLanguages();
      setLanguages(allLanguages);
    };
    getAllLanguages();

    const getEditorVenueUsers = async () => {
      if (authData?.user.role !== UserRoleType.Admin && !venue) return;
      const users = await getUsersByEditorType(UserRoleType.VenueEditor);
      if (users) {
        setVenueUsers(users);
      }
    };
    getEditorVenueUsers();
  }, []);

  const venueSectionsForSelected = (): SelectedFieldOption[] | any[] => {
    const venueBanners = venue?.banners && venue?.banners.length > 0 ? venue?.banners : [];
    const allVenueSectionTypesParsed = parseAllVenueSectionTypes().map((section) => {
      return {
        label: section.description,
        value: section.id,
      };
    });

    if (venueBanners.length > 0) {
      const parseBanners = venueBanners.map((banner) => {
        return {
          label: banner?.title,
          value: banner?.id,
          bannerId: banner?.id,
        };
      });
      return allVenueSectionTypesParsed.concat(parseBanners);
    }
    return allVenueSectionTypesParsed;
  };

  const commercesForSelect = (commerces: Commerce[]) => {
    return commerces.map((commerce) => {
      return {
        label: commerce.name,
        value: commerce.id,
      };
    });
  };

  const appsForSelect = (apps: App[]) => {
    return apps.map((app) => {
      return {
        label: app.description,
        value: app.id,
      };
    });
  };

  const languagesForSelect = (languages: Language[]) => {
    return languages.map((language) => {
      return {
        label: language.description,
        value: language.code,
      };
    });
  };

  const usersForSelect = (users: User[]): SelectedFieldOption[] => {
    return users.map((user) => {
      return {
        label: user.name,
        value: user.id,
      };
    });
  };

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const onConfirm = async (): Promise<void> => {
    const formData = new FormData();
    formData.append('name', formik.values.name);
    formData.append('code', formik.values.code);
    if (formik.values.appColor) formData.append('appColor', formik.values.appColor);
    if (formik.values.appSecondaryColor) formData.append('appSecondaryColor', formik.values.appSecondaryColor);
    formData.append('language', formik.values.language);
    formData.append('order', formik.values.order?.toString());
    formData.append('billingRange', formik.values.billingRange.toString());
    formData.append('timeZoneIdentifier', formik.values.timeZoneIdentifier);
    formData.append('useDifferentIconSet', formik.values.useDifferentIconSet ? '1' : '0');
    if (formik.values.wallioUrl) {
      formData.append('wallioUrl', formik.values.wallioUrl);
    }
    if (formik.values.wallioTitle) {
      formData.append('wallioTitle', formik.values.wallioTitle);
    }

    const parseCommercesKeys = (): Commerce[] => {
      if (!commerces || !formik.values.commerces) return [];
      return commerces.filter((commerce) => formik.values.commerces.includes(commerce.id));
    };
    formData.append('commerces', JSON.stringify(parseCommercesKeys()));

    const parseAppsKeys = (): App[] => {
      if (!apps || !formik.values.apps) return [];
      return apps.filter((apps) => formik.values.apps.includes(apps.id));
    };
    formData.append('apps', JSON.stringify(parseAppsKeys()));
    formData.append('logo', formik.values.logo as unknown as File);
    if (formik.values.map) formData.append('map', formik.values.map as unknown as File);
    if (formik.values.venueMap) formData.append('venueMap', formik.values.venueMap as unknown as File);
    if (formik.values.banner) formData.append('banner', formik.values.banner as unknown as File);
    if (formik.values.useDifferentIconSet && formik.values.logoForTinting)
      formData.append('logoForTinting', formik.values.logoForTinting as unknown as File);

    try {
      let result: Venue | null = null;
      if (venue) {
        formData.append('enabled', formik.values.enabled.toString());
        if (formik.values.venueEditor) {
          formData.append('venueEditor', formik.values.venueEditor);
        }
        if (formik.values.downloadBanner) {
          formData.append('downloadBanner', formik.values.downloadBanner as unknown as File);
        }
        if (formik.values.entityIdentificator) {
          formData.append('entityIdentificator', formik.values.entityIdentificator.toString());
        }
        if (formik.values.cloverMiniBackgroundImage) {
          formData.append('cloverMiniBackgroundImage', formik.values.cloverMiniBackgroundImage as unknown as File);
        }
        if (formik.values.cloverMiniLowerBanner) {
          formData.append('cloverMiniLowerBanner', formik.values.cloverMiniLowerBanner as unknown as File);
        }
        if (formik.values.cloverFlexBackgroundImage) {
          formData.append('cloverFlexBackgroundImage', formik.values.cloverFlexBackgroundImage as unknown as File);
        }
        if (formik.values.cloverFlexLowerBanner) {
          formData.append('cloverFlexLowerBanner', formik.values.cloverFlexLowerBanner as unknown as File);
        }

        if (formik.values.sections) {
          const parseSectionKeys = (): VenueSection<VenueSectionType>[] => {
            if (!venueSections || !formik.values.sections) return [];
            return venueSections.filter((section) => {
              if (section.banner) {
                return formik.values.sections.includes(section.banner.id);
              } else {
                return formik.values.sections.includes(section.type);
              }
            });
          };
          formData.append(
            'sections',
            JSON.stringify(
              parseSectionKeys().map((section) => {
                return {
                  type: section.type,
                  order: section.order.toString(),
                  bannerId: section.banner ? section.banner.id : null,
                };
              }),
            ),
          );
        }
        if (hasBettingSection) {
          formData.append('bettingTitle', formik.values.bettingTitle);
          formData.append('bettingUrl', formik.values.bettingUrl);
        }
        result = await updateVenue(venue.id, formData);
      } else {
        result = await storeVenue(formData);
      }
      if (result) {
        history.go(-1);
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: venue?.name ?? '',
      code: venue?.code ?? '',
      appColor: venue?.appColor ?? '',
      appSecondaryColor: venue?.appSecondaryColor ?? '',
      wallioUrl: venue?.wallioUrl ?? '',
      wallioTitle: venue?.wallioTitle ?? '',
      bettingTitle: venue?.bettingTitle ?? '',
      bettingUrl: venue?.bettingUrl ?? '',
      logo: logo?.data ?? null,
      map: map?.data ?? null,
      venueMap: venueMap?.data ?? null,
      downloadBanner: downloadBannerImage?.data ?? null,
      banner: banner?.data ?? null,
      cloverMiniBackgroundImage: cloverMiniBackgroundImage?.data ?? null,
      cloverMiniLowerBanner: cloverMiniLowerBanner?.data ?? null,
      cloverFlexBackgroundImage: cloverFlexBackgroundImage?.data ?? null,
      cloverFlexLowerBanner: cloverFlexLowerBanner?.data ?? null,
      logoForTinting: venueLogoForTinting?.data ?? null,
      commerces: venue?.commerces?.map((commerce) => commerce.id) ?? [],
      language: venue?.language ?? '',
      order: venue?.order ?? '',
      billingRange: venue?.billingRange ?? '',
      apps: venue?.apps?.map((app) => app.id) ?? [],
      enabled: venue?.enabled ?? true,
      useDifferentIconSet: venue?.useDifferentIconSet ?? false,
      venueEditor: venue?.editorId ?? '',
      entityIdentificator: venue?.entityIdentificator ?? '',
      sections:
        venue?.sections?.map((section) => {
          if (section.banner) {
            return section.banner?.id;
          } else {
            return section.type;
          }
        }) ?? [],
      timeZoneIdentifier: venue?.timeZoneIdentifier ?? '',
    },
    validationSchema: venue ? EditForm(t, hasBettingSection) : CreateForm(t),
    onSubmit: () => {
      setOpenDialog(true);
    },
  });

  React.useEffect(() => {
    const checkForDuplicateVenueEditor = async () => {
      if (!formik.values.venueEditor) return;
      const allVenues = await getAllVenues();
      if (allVenues) {
        if (venue?.editorId) {
          const indexOfCurrentVenue = allVenues.findIndex((arrayVenue) => arrayVenue.id === venue.id);
          allVenues.splice(indexOfCurrentVenue, 1);
        }
        const findVenueWithCurrentEditorId = allVenues.find((venue) => venue.editorId === formik.values.venueEditor);
        if (findVenueWithCurrentEditorId) {
          const currentVenueEditorId = formik.values.venueEditor;
          setErrorSameEditor(
            `${
              (t('general-suggestions.not-repeated-venue-editor'),
              { editorId: currentVenueEditorId, name: findVenueWithCurrentEditorId.name })
            }`,
          );
          formik.setFieldValue('venueEditor', '');
        } else {
          setErrorSameEditor('');
        }
      }
    };
    checkForDuplicateVenueEditor();
  }, [formik.values.venueEditor]);

  React.useEffect(() => {
    const addVenueSections = () => {
      if (formik.values.sections.length > 0) {
        formik.values.sections.forEach((section) => {
          const filteringSection = venueSections.find((venueSection) => {
            if (typeof section === 'string') {
              return venueSection?.banner?.id === section;
            } else {
              return venueSection.type === section;
            }
          });

          if (!filteringSection) {
            setVenueSections(
              venueSections.concat({
                id: '',
                type: typeof section === 'string' ? Number(VenueSectionType.Banner) : section,
                order: Math.max(...venueSections.map((section) => section.order)) + 1,
                banner:
                  typeof section === 'string' ? venue?.banners?.find((banner) => banner?.id === section) : undefined,
              }),
            );
          }
        });
      } else {
        setVenueSections([]);
      }
    };
    addVenueSections();

    const substractVenueSections = () => {
      const currentVenueSectionTypes = venueSections
        .map((section) => {
          if (section.banner) {
            return section.banner.id;
          } else {
            return section.type;
          }
        })
        .every((type) => formik.values.sections.includes(type));
      if (!currentVenueSectionTypes) {
        const filteringCurrentVenueSectionTypes = venueSections.filter((currentSection) => {
          if (currentSection.banner) {
            return formik.values.sections.includes(currentSection.banner.id);
          } else {
            return formik.values.sections.includes(currentSection.type);
          }
        });
        setVenueSections(filteringCurrentVenueSectionTypes);
      }
    };
    substractVenueSections();
  }, [formik.values.sections]);

  return (
    <>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <TextField
          margin="dense"
          fullWidth
          id="name"
          name="name"
          label={t('venue-form-name')}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="code"
          name="code"
          label={t('venue-form-code')}
          value={formik.values.code}
          onChange={formik.handleChange}
          error={formik.touched.code && Boolean(formik.errors.code)}
          helperText={formik.touched.code && formik.errors.code}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="appColor"
          name="appColor"
          label={t('venue-form-appColor')}
          value={formik.values.appColor}
          onChange={formik.handleChange}
          error={formik.touched.appColor && Boolean(formik.errors.appColor)}
          helperText={formik.touched.appColor && formik.errors.appColor}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="appSecondaryColor"
          name="appSecondaryColor"
          label={t('venue-form-appSecondaryColor')}
          value={formik.values.appSecondaryColor}
          onChange={formik.handleChange}
          error={formik.touched.appSecondaryColor && Boolean(formik.errors.appSecondaryColor)}
          helperText={formik.touched.appSecondaryColor && formik.errors.appSecondaryColor}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="wallioUrl"
          name="wallioUrl"
          label={t('venue-form-wallio-url')}
          value={formik.values.wallioUrl}
          onChange={formik.handleChange}
          error={formik.touched.wallioUrl && Boolean(formik.errors.wallioUrl)}
          helperText={formik.touched.wallioUrl && formik.errors.wallioUrl}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="wallioTitle"
          name="wallioTitle"
          label={t('venue-form-wallio-title')}
          value={formik.values.wallioTitle}
          onChange={formik.handleChange}
          error={formik.touched.wallioTitle && Boolean(formik.errors.wallioTitle)}
          helperText={formik.touched.wallioTitle && formik.errors.wallioTitle}
          disabled={formik.isSubmitting}
        />
        {hasBettingSection && (
          <>
            <TextField
              margin="dense"
              fullWidth
              id="bettingTitle"
              name="bettingTitle"
              label={t('venue-form-betting-title')}
              value={formik.values.bettingTitle}
              onChange={formik.handleChange}
              error={formik.touched.bettingTitle && Boolean(formik.errors.bettingTitle)}
              helperText={formik.touched.bettingTitle && formik.errors.bettingTitle}
              disabled={formik.isSubmitting}
            />
            <TextField
              margin="dense"
              fullWidth
              id="bettingUrl"
              name="bettingUrl"
              label={t('venue-form-betting-url')}
              value={formik.values.bettingUrl}
              onChange={formik.handleChange}
              error={formik.touched.bettingUrl && Boolean(formik.errors.bettingUrl)}
              helperText={formik.touched.bettingUrl && formik.errors.bettingUrl}
              disabled={formik.isSubmitting}
            />
          </>
        )}
        {venue && (
          <FormControlLabel
            id="enabled"
            name="enabled"
            control={<Switch checked={formik.values.enabled} onChange={formik.handleChange} />}
            label={t('venue-form-enabled')}
            disabled={formik.isSubmitting}
          />
        )}
        <FormControlLabel
          id="useDifferentIconSet"
          name="useDifferentIconSet"
          control={<Switch checked={formik.values.useDifferentIconSet} onChange={formik.handleChange} />}
          label={t('venue-form-use-different-icon-set')}
          disabled={formik.isSubmitting}
        />
        <div className="vertical-bottom-space">
          <ImageInput
            label={`${formik.values.logo || logo ? t('venue-form-logo-edit') : t('venue-form-logo-create')}`}
            name="logo"
            isSubmitting={formik.isSubmitting}
            value={formik.values.logo}
            setFieldValue={formik.setFieldValue}
            idealSize={{ width: 350, height: 350 }}
            touched={formik.touched.logo}
            errors={formik.errors.logo}
            preview={logo?.preview}
          />
        </div>
        {formik.values.useDifferentIconSet && (
          <div className="vertical-bottom-space">
            <ImageInput
              label={`${
                formik.values.logoForTinting || venueLogoForTinting
                  ? t('venue-form-logoForTinting-edit')
                  : t('venue-form-logoForTinting-create')
              }`}
              name="logoForTinting"
              isSubmitting={formik.isSubmitting}
              value={formik.values.logoForTinting}
              setFieldValue={formik.setFieldValue}
              idealSize={{ width: 35, height: 35 }}
              touched={formik.touched.logoForTinting}
              errors={formik.errors.logoForTinting}
              preview={venueLogoForTinting?.preview}
            />
            <div className="user-message">{t('venue-form-logoForTinting-advice')}</div>
          </div>
        )}
        <div className="vertical-bottom-space">
          <ImageInput
            label={`${formik.values.map || map ? t('venue-form-map-edit') : t('venue-form-map-create')}`}
            name="map"
            isSubmitting={formik.isSubmitting}
            value={formik.values.map}
            setFieldValue={formik.setFieldValue}
            idealSize={{ width: 700, height: 700 }}
            touched={formik.touched.map}
            errors={formik.errors.map}
            preview={map?.preview}
          />
        </div>
        <div className="vertical-bottom-space">
          <ImageInput
            label={`${
              formik.values.venueMap || venueMap ? t('venue-form-venueMap-edit') : t('venue-form-venueMap-create')
            }`}
            name="venueMap"
            isSubmitting={formik.isSubmitting}
            value={formik.values.venueMap}
            setFieldValue={formik.setFieldValue}
            idealSize={{ width: 320, height: 280 }}
            touched={formik.touched.venueMap}
            errors={formik.errors.venueMap}
            preview={venueMap?.preview}
          />
        </div>
        {venue && (
          <div className="vertical-bottom-space">
            <ImageInput
              label={`${
                formik.values.downloadBanner || downloadBannerImage
                  ? t('venue-form-downloadBanner-edit')
                  : t('venue-form-downloadBanner-create')
              }`}
              name="downloadBanner"
              isSubmitting={formik.isSubmitting}
              value={formik.values.downloadBanner}
              setFieldValue={formik.setFieldValue}
              idealSize={{ width: 700, height: 150 }}
              touched={formik.touched.downloadBanner}
              errors={formik.errors.downloadBanner}
              preview={downloadBannerImage?.preview}
            />
          </div>
        )}
        <div className="vertical-bottom-space">
          <ImageInput
            label={`${formik.values.banner || banner ? t('venue-form-banner-edit') : t('venue-form-banner-create')}`}
            name="banner"
            isSubmitting={formik.isSubmitting}
            value={formik.values.banner}
            setFieldValue={formik.setFieldValue}
            idealSize={{ width: 700, height: 300 }}
            touched={formik.touched.banner}
            errors={formik.errors.banner}
            preview={banner?.preview}
          />
        </div>

        {venue && (
          <>
            <div className="vertical-bottom-space">
              <ImageInput
                label={`${
                  formik.values.cloverMiniBackgroundImage || cloverMiniBackgroundImage
                    ? t('venue-form-cloverMini-background-image-edit')
                    : t('venue-form-cloverMini-background-image-create')
                }`}
                name="cloverMiniBackgroundImage"
                isSubmitting={formik.isSubmitting}
                value={formik.values.cloverMiniBackgroundImage}
                setFieldValue={formik.setFieldValue}
                idealSize={{ width: 1280, height: 800 }}
                touched={formik.touched.cloverMiniBackgroundImage}
                errors={formik.errors.cloverMiniBackgroundImage}
                preview={cloverMiniBackgroundImage?.preview}
              />
            </div>
            <div className="vertical-bottom-space">
              <ImageInput
                label={`${
                  formik.values.cloverMiniLowerBanner || cloverMiniLowerBanner
                    ? t('venue-form-cloverMini-lower-banner-image-edit')
                    : t('venue-form-cloverMini-lower-banner-image-create')
                }`}
                name="cloverMiniLowerBanner"
                isSubmitting={formik.isSubmitting}
                value={formik.values.cloverMiniLowerBanner}
                setFieldValue={formik.setFieldValue}
                idealSize={{ width: 1280, height: 185 }}
                touched={formik.touched.cloverMiniLowerBanner}
                errors={formik.errors.cloverMiniLowerBanner}
                preview={cloverMiniLowerBanner?.preview}
              />
            </div>
            <div className="vertical-bottom-space">
              <ImageInput
                label={`${
                  formik.values.cloverFlexBackgroundImage || cloverFlexBackgroundImage
                    ? t('venue-form-cloverFlex-background-image-edit')
                    : t('venue-form-cloverFlex-background-image-create')
                }`}
                name="cloverFlexBackgroundImage"
                isSubmitting={formik.isSubmitting}
                value={formik.values.cloverFlexBackgroundImage}
                setFieldValue={formik.setFieldValue}
                idealSize={{ width: 360, height: 720 }}
                touched={formik.touched.cloverFlexBackgroundImage}
                errors={formik.errors.cloverFlexBackgroundImage}
                preview={cloverFlexBackgroundImage?.preview}
              />
            </div>
            <div className="vertical-bottom-space">
              <ImageInput
                label={`${
                  formik.values.cloverFlexLowerBanner || cloverFlexLowerBanner
                    ? t('venue-form-cloverFlex-lower-banner-image-edit')
                    : t('venue-form-cloverFlex-lower-banner-image-create')
                }`}
                name="cloverFlexLowerBanner"
                isSubmitting={formik.isSubmitting}
                value={formik.values.cloverFlexLowerBanner}
                setFieldValue={formik.setFieldValue}
                idealSize={{ width: 360, height: 145 }}
                touched={formik.touched.cloverFlexLowerBanner}
                errors={formik.errors.cloverFlexLowerBanner}
                preview={cloverFlexLowerBanner?.preview}
              />
            </div>
            {(authData?.user?.role === UserRoleType.Admin || authData?.user?.role === UserRoleType.VenueEditor) && (
              <>
                <div className="vertical-bottom-space">
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => history.push(`/private/venues/${venue.id}/edit/payment-config`)}
                  >
                    {t('venue-form-paymentMethods-edit')}
                  </Button>
                </div>
                <div className="vertical-bottom-space">
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => history.push(`/private/venues/${venue.id}/edit/factory-widgets`)}
                  >
                    {t('venue-form-factory-widgets-edit')}
                  </Button>
                </div>
              </>
            )}
            <div className="vertical-bottom-space">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={(): void => history.push(`/private/venues/${venue.id}/edit/sns-config`)}
              >
                {t('venue-form-socialMedia-edit')}
              </Button>
            </div>
            <div className="vertical-bottom-space">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={(): void => history.push(`/private/venues/${venue.id}/edit/delivery-config`)}
              >
                {t('venue-form-delibery-edit')}
              </Button>
            </div>
          </>
        )}
        {venue && commerces && (
          <SelectField
            name="commerces"
            label={t('venue-form-commerces-label')}
            placeholder={t('venue-form-commerces-placeholder')}
            value={formik.values.commerces}
            options={commercesForSelect(commerces)}
            isMulti={true}
            setFieldValue={formik.setFieldValue}
            onBlur={formik.handleBlur}
            touched={formik.touched.commerces}
            error={formik.errors.commerces}
            isClearable={true}
            backspaceRemovesValue={true}
            isDisabled={formik.isSubmitting}
            isSearchable={true}
          />
        )}
        {authData?.user.role === UserRoleType.Admin && venueUsers && venue && (
          <SelectField
            name="venueEditor"
            label={t('venue-form-venue-users-label')}
            placeholder={t('venue-form-venue-users-select-placeholder')}
            value={formik.values.venueEditor}
            options={usersForSelect(venueUsers)}
            isMulti={false}
            setFieldValue={formik.setFieldValue}
            onBlur={formik.handleBlur}
            touched={formik.touched.venueEditor}
            error={formik.errors.venueEditor}
            isClearable={true}
            backspaceRemovesValue={true}
            isDisabled={formik.isSubmitting}
            isSearchable={true}
          />
        )}
        {errorSameEditor && (
          <div>
            <Typography color="error" variant="h6" component="h2">
              {errorSameEditor}
            </Typography>
          </div>
        )}
        {languages && (
          <SelectField
            name="language"
            label={t('venue-form-language-label')}
            placeholder={t('venue-form-language-placeholder')}
            value={formik.values.language}
            options={languagesForSelect(languages)}
            isMulti={false}
            setFieldValue={formik.setFieldValue}
            onBlur={formik.handleBlur}
            touched={formik.touched.language}
            error={formik.errors.language}
            isClearable={false}
            backspaceRemovesValue={false}
            isDisabled={formik.isSubmitting}
            isSearchable={true}
          />
        )}
        <TextField
          margin="dense"
          fullWidth
          id="timeZoneIdentifier"
          name="timeZoneIdentifier"
          label={t('venue-form-timezone-identifier')}
          value={formik.values.timeZoneIdentifier}
          onChange={formik.handleChange}
          error={formik.touched.timeZoneIdentifier && Boolean(formik.errors.timeZoneIdentifier)}
          helperText={formik.touched.timeZoneIdentifier && formik.errors.timeZoneIdentifier}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="order"
          name="order"
          label={t('venue-form-order-label')}
          value={formik.values.order}
          onChange={formik.handleChange}
          error={formik.touched.order && Boolean(formik.errors.order)}
          helperText={formik.touched.order && formik.errors.order}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="entityIdentificator"
          name="entityIdentificator"
          label={t('venue-form-entity-identificator')}
          value={formik.values.entityIdentificator}
          onChange={formik.handleChange}
          error={formik.touched.entityIdentificator && Boolean(formik.errors.entityIdentificator)}
          helperText={formik.touched.entityIdentificator && formik.errors.entityIdentificator}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="billingRange"
          name="billingRange"
          label={t('venue-form-billing-range')}
          value={formik.values.billingRange}
          InputProps={{
            endAdornment: <InputAdornment position="end">Horas</InputAdornment>,
          }}
          onChange={formik.handleChange}
          error={formik.touched.billingRange && Boolean(formik.errors.billingRange)}
          helperText={formik.touched.billingRange && formik.errors.billingRange}
          disabled={formik.isSubmitting}
        />
        {apps && (
          <SelectField
            name="apps"
            label={t('venue-form-apps-label')}
            placeholder={t('venue-form-apps-placeholder')}
            value={formik.values.apps}
            options={appsForSelect(apps)}
            isMulti={true}
            setFieldValue={formik.setFieldValue}
            onBlur={formik.handleBlur}
            touched={formik.touched.commerces}
            error={formik.errors.commerces}
            isClearable={true}
            backspaceRemovesValue={true}
            isDisabled={formik.isSubmitting}
            isSearchable={true}
          />
        )}
        {venue && (
          <>
            <SelectField
              name="sections"
              label={t('venue-form-sections')}
              placeholder={t('venue-form-sections-placeholder')}
              value={formik.values.sections}
              options={venueSectionsForSelected()}
              isMulti={true}
              setFieldValue={formik.setFieldValue}
              onBlur={formik.handleBlur}
              touched={formik.touched.sections}
              error={formik.errors.sections}
              isClearable={true}
              backspaceRemovesValue={true}
              isDisabled={formik.isSubmitting}
              isSearchable={true}
            />
            {venueSections.length > 0 && (
              <List>
                {venueSections.map((section, index) => (
                  <>
                    <ListItem key={index}>
                      <TextField
                        id="order"
                        label={`${parseVenueSectionType(section.type, section.banner)}`}
                        margin="dense"
                        name="order"
                        type="number"
                        value={section.order}
                        error={uniqueVenueSectionOrders.size !== currentVenueSectionOrders.length}
                        inputProps={{
                          inputMode: 'numeric',
                          pattern: '[1-9]*',
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">{t('venue-form-sections-order')}</InputAdornment>
                          ),
                        }}
                        onChange={(event) => {
                          venueSections[index].order = Number(event.target.value);
                          setVenueSections([...venueSections]);
                        }}
                        onKeyDown={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        disabled={formik.isSubmitting}
                      />
                    </ListItem>
                  </>
                ))}
              </List>
            )}
            {uniqueVenueSectionOrders.size !== currentVenueSectionOrders.length && (
              <Typography color="error">{t('venue-form-sections-order-error')}</Typography>
            )}
          </>
        )}
        {venue && (
          <>
            <div>
              <Button
                fullWidth
                variant="contained"
                onClick={() => history.push(`/private/venues/${venue.id}/banners`)}
                disabled={formik.isSubmitting}
              >
                {t('venue-form-banners')}
              </Button>
            </div>
            <div className="footer">
              <Button
                fullWidth
                variant="contained"
                onClick={() => history.push(`/private/venues/${venue.id}/events`)}
                disabled={formik.isSubmitting}
              >
                {t('venue-form-events')}
              </Button>
            </div>
            <div className="footer">
              <Button
                fullWidth
                variant="contained"
                color="default"
                onClick={() => history.push(`/private/venues/${venue.id}/articles`)}
                disabled={formik.isSubmitting}
              >
                {t('venue-form-notices')}
              </Button>
            </div>
          </>
        )}
        <div className="footer">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            disabled={uniqueVenueSectionOrders.size !== currentVenueSectionOrders.length || formik.isSubmitting}
          >
            {`${venue ? t('venue-form-venue-edit') : t('venue-form-venue-create')} `}
          </Button>
        </div>
      </form>
    </>
  );
};
