import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import EnglishTranslation from './locales/en/translations.json';
import SpanishTranslation from './locales/es/translations.json';
import PortugueseTranslation from './locales/pt/translations.json';

i18n.use(initReactI18next).init({
  fallbackLng: 'es',
  lng: 'es',
  resources: {
    en: {
      translations: EnglishTranslation,
    },
    es: {
      translations: SpanishTranslation,
    },
    pt: {
      translations: PortugueseTranslation,
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
});

i18n.languages = ['en', 'es', 'pt'];

export default i18n;
