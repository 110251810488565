import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialog';
import { storeFiservQrSubsidiaryConfig, editFiservQrSubsidiaryConfig } from 'services/fiservQrServices';
import FiservQrSubsidiaryForm from './schemas/FiservQrSubsidiaryForm';
import FiservQrSubsidiaryFormProps from 'types/forms/FiservQrSubsidiaryFormProps';

export default ({ config }: FiservQrSubsidiaryFormProps): JSX.Element => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const { t } = useTranslation();

  const onConfirm = async (): Promise<void> => {
    try {
      const data = config
        ? await editFiservQrSubsidiaryConfig(config.id, formik.values)
        : await storeFiservQrSubsidiaryConfig(formik.values);
      if (data) {
        history.push('/private/admin/payments/fiserv-qr');
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      id: config?.id ?? '',
      name: config?.name ?? '',
      address: config?.address ?? '',
      phone: config?.phone ?? '',
      streetNumber: config?.streetNumber ?? '',
      streetName: config?.streetName ?? '',
      city: config?.city ?? '',
      postalCode: config?.postalCode ?? '',
      department: config?.department ?? '',
      country: config?.country ?? '',
      state: config?.state ?? '',
      token: '',
    },
    validationSchema: FiservQrSubsidiaryForm(t, !config),
    onSubmit: async () => {
      setOpenDialog(true);
    },
  });
  console.log({ errors: formik.errors });
  return (
    <div>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="name"
            name="name"
            type="string"
            label={t('fiservQrSubsidiaryConfig-form-name')}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="address"
            name="address"
            label={t('fiservQrSubsidiaryConfig-form-address')}
            type="string"
            value={formik.values.address}
            onChange={formik.handleChange}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="phone"
            name="phone"
            label={t('fiservQrSubsidiaryConfig-form-phone')}
            type="string"
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="streetNumber"
            name="streetNumber"
            label={t('fiservQrSubsidiaryConfig-form-streetNumber')}
            type="string"
            value={formik.values.streetNumber}
            onChange={formik.handleChange}
            error={formik.touched.streetNumber && Boolean(formik.errors.streetNumber)}
            helperText={formik.touched.streetNumber && formik.errors.streetNumber}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="streetName"
            name="streetName"
            label={t('fiservQrSubsidiaryConfig-form-streetName')}
            type="string"
            value={formik.values.streetName}
            onChange={formik.handleChange}
            error={formik.touched.streetName && Boolean(formik.errors.streetName)}
            helperText={formik.touched.streetName && formik.errors.streetName}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="city"
            name="city"
            label={t('fiservQrSubsidiaryConfig-form-city')}
            type="string"
            value={formik.values.city}
            onChange={formik.handleChange}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="postalCode"
            name="postalCode"
            label={t('fiservQrSubsidiaryConfig-form-postalCode')}
            type="string"
            value={formik.values.postalCode}
            onChange={formik.handleChange}
            error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
            helperText={formik.touched.postalCode && formik.errors.postalCode}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="department"
            name="department"
            label={t('fiservQrSubsidiaryConfig-form-department')}
            type="string"
            value={formik.values.department}
            onChange={formik.handleChange}
            error={formik.touched.department && Boolean(formik.errors.department)}
            helperText={formik.touched.department && formik.errors.department}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="country"
            name="country"
            label={t('fiservQrSubsidiaryConfig-form-country')}
            type="string"
            value={formik.values.country}
            onChange={formik.handleChange}
            error={formik.touched.country && Boolean(formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="state"
            name="state"
            label={t('fiservQrSubsidiaryConfig-form-state')}
            type="string"
            value={formik.values.state}
            onChange={formik.handleChange}
            error={formik.touched.state && Boolean(formik.errors.state)}
            helperText={formik.touched.state && formik.errors.state}
          />
        </div>
        {!config && (
          <div className="vertical-bottom-space">
            <TextField
              fullWidth
              id="token"
              name="token"
              type="password"
              label={t('fiservQrSubsidiaryConfig-form-token')}
              value={formik.values.token}
              onChange={formik.handleChange}
              error={formik.touched.token && Boolean(formik.errors.token)}
              helperText={formik.touched.token && formik.errors.token}
            />
          </div>
        )}
        {config && (
          <div className="vertical-bottom-space">
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => history.push(`/private/admin/payments/fiserv-qr/sales-stands`)}
            >
              {t('fiservQrSubsidiaryConfig-manageSalesStands')}
            </Button>
          </div>
        )}
        <div className="vertical-bottom-space">
          <Button color="primary" variant="contained" fullWidth type="submit">
            {`${config ? t('fiservQrSubsidiaryConfig-form-edit') : t('fiservQrSubsidiaryConfig-form-create')}`}
          </Button>
        </div>
      </form>
    </div>
  );
};
