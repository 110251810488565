import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, TextField, Typography } from '@material-ui/core';
import { storeFranchise, updateFranchise, getAllFranchises } from 'services/franchiseServices';
import { getCommerces } from 'services/commerceServices';
import { getUsersByEditorType } from 'services/userServices';
import SelectField from 'components/SelectField';
import ConfirmDialog from 'components/ConfirmDialog';
import AppContext from 'contexts/AppContext';
import Commerce from 'types/models/Commerce';
import Franchise from 'types/models/Franchise';
import User from 'types/models/User';
import SelectedFieldOption from 'types/models/SelectFieldOption';
import UserRoleType from 'types/enums/UserRoleType';
import FranchiseFormProps from 'types/forms/FranchiseFormProps';
import { CreateForm, EditForm } from './schemas/FranchiseForm';

export default ({ franchise }: FranchiseFormProps): JSX.Element => {
  console.log('estoy en franchiseForm. borrar comment');

  const history = useHistory();
  const { authData } = React.useContext(AppContext);
  const [commerces, setCommerces] = React.useState<Commerce[] | null>(null);
  const [franchiseUsers, setFranchiseUsers] = React.useState<User[] | null>(null);
  const [errorSameEditor, setErrorSameEditor] = React.useState<string>('');
  const [openDialog, setOpenDialog] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    const getAllCommerces = async () => {
      const allCommerces = await getCommerces();
      setCommerces(allCommerces);
    };
    getAllCommerces();

    const getFranchiseUsers = async () => {
      if (authData?.user.role !== UserRoleType.Admin && !franchise) return;
      const users = await getUsersByEditorType(UserRoleType.FranchiseEditor);
      if (users) {
        setFranchiseUsers(users);
      }
    };
    getFranchiseUsers();
  }, []);

  const commercesForSelect = (commerces: Commerce[]) => {
    return commerces.map((commerce) => {
      return {
        label: commerce.name,
        value: commerce.id,
      };
    });
  };

  const usersForSelect = (users: User[]): SelectedFieldOption[] => {
    return users.map((user) => {
      return {
        label: user.name,
        value: user.id,
      };
    });
  };

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const onConfirm = async (): Promise<void> => {
    const parseCommercesKeys = (): Commerce[] => {
      if (!commerces || !formik.values.commerces) return [];
      return commerces.filter((commerce) => formik.values.commerces.includes(commerce.id));
    };

    const dataFranchise: Franchise = {
      id: '',
      name: formik.values.name,
      commerces: parseCommercesKeys(),
    };

    try {
      let result: Franchise | null;
      if (franchise) {
        dataFranchise.editorId = formik.values.franchiseEditor;
        result = await updateFranchise(franchise.id, dataFranchise);
      } else {
        result = await storeFranchise(dataFranchise);
      }
      if (result) {
        history.go(-1);
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: franchise?.name ?? '',
      commerces: franchise?.commerces?.map((commerce) => commerce.id) ?? [],
      franchiseEditor: franchise?.editorId ?? '',
    },
    validationSchema: franchise ? EditForm(t) : CreateForm(t),
    onSubmit: () => {
      setOpenDialog(true);
    },
  });

  React.useEffect(() => {
    const checkForDuplicateFranchiseEditor = async () => {
      if (!formik.values.franchiseEditor) return;
      const allFranchises = await getAllFranchises();
      if (allFranchises) {
        if (franchise?.editorId) {
          const indexOfCurrentFranchise = allFranchises.findIndex(
            (arrayFranchise) => arrayFranchise.id === franchise.id,
          );
          allFranchises.splice(indexOfCurrentFranchise, 1);
        }
        const findFranchiseWithCurrentEditorId = allFranchises.find(
          (franchise) => franchise.editorId === formik.values.franchiseEditor,
        );
        if (findFranchiseWithCurrentEditorId) {
          const currentFranchiseEditorId = formik.values.franchiseEditor;
          setErrorSameEditor(
            t('general-suggestions.not-repeated-franchise-editor', {
              editorId: currentFranchiseEditorId,
              name: findFranchiseWithCurrentEditorId.name,
            }),
          );
          formik.setFieldValue('franchiseEditor', '');
        } else {
          setErrorSameEditor('');
        }
      }
    };
    checkForDuplicateFranchiseEditor();
  }, [formik.values.franchiseEditor]);

  return (
    <>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <TextField
          margin="dense"
          fullWidth
          id="name"
          name="name"
          label={t('franchise-form-name-label')}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          disabled={formik.isSubmitting}
        />
        {commerces && (
          <SelectField
            name="commerces"
            label={t('venue-form-commerces-label')}
            placeholder={t('franchise-form-commerces-placeholder')}
            value={formik.values.commerces}
            options={commercesForSelect(commerces)}
            isMulti={true}
            setFieldValue={formik.setFieldValue}
            onBlur={formik.handleBlur}
            touched={formik.touched.commerces}
            error={formik.errors.commerces}
            isClearable={true}
            backspaceRemovesValue={true}
            isDisabled={formik.isSubmitting}
            isSearchable={true}
          />
        )}
        {(authData?.user.role === UserRoleType.Admin || authData?.user.role === UserRoleType.VenueEditor) &&
          franchiseUsers &&
          franchise && (
            <SelectField
              name="franchiseEditor"
              label={t('franchise-form-user-label')}
              placeholder={t('franchise-form-user-placeholder-select')}
              value={formik.values.franchiseEditor}
              options={usersForSelect(franchiseUsers)}
              isMulti={false}
              setFieldValue={formik.setFieldValue}
              onBlur={formik.handleBlur}
              touched={formik.touched.franchiseEditor}
              error={formik.errors.franchiseEditor}
              isClearable={true}
              backspaceRemovesValue={true}
              isDisabled={formik.isSubmitting}
              isSearchable={true}
            />
          )}
        {errorSameEditor && (
          <div>
            <Typography color="error" variant="h6" component="h2">
              {errorSameEditor}
            </Typography>
          </div>
        )}
        <div className="footer">
          <Button fullWidth variant="contained" color="primary" type="submit" disabled={formik.isSubmitting}>
            {`${franchise ? t('franchise-form-edit') : t('franchise-form-create')} `}
          </Button>
        </div>
      </form>
    </>
  );
};
