import * as yup from 'yup';
import { enabledValidation, imageValidation } from 'utils/yup';
import { TFunction } from 'react-i18next';

export const CreateForm = (t: TFunction): any =>
  yup.object().shape({
    name: yup.string().required(t('schema-commerce-form-name-required')),
    code: yup
      .string()
      .required(t('schema-commerce-form-code-required'))
      .min(6, t('schema-commerce-form-code-min'))
      .max(36, t('schema-commerce-form-code-max'))
      .matches(/^\S*$/, t('schema-commerce-form-code-matches')),
    address: yup.string().required(t('schema-commerce-form-address-required')),
    email: yup.string().email(t('schema-commerce-form-email-email')).required(t('schema-commerce-form-email-required')),
    phone: yup
      .string()
      .required(t('schema-commerce-form-phone-required'))
      .matches(/([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/, t('schema-commerce-form-phone-matches')),
    categories: yup.array().of(yup.string()).min(1, t('schema-commerce-form-categories-min')),
    type: yup.string().required(t('schema-commerce-form-type-required')),
    logo: imageValidation('Logo', true, t),
    banner: imageValidation('Banner', true, t),
    integrationMaxirest: enabledValidation(t),
    hideLogoOnList: enabledValidation(t),
    maxirestStoreId: yup.mixed().when('integrationMaxirest', {
      is: true,
      then: yup.string().required(t('schema-commerce-form-maxirestStoreId-required')),
      otherwise: yup.string(),
    }),
    maxirestPartner: yup.mixed().when('integrationMaxirest', {
      is: true,
      then: yup.string().required(t('schema-commerce-form-maxirestPartner-required')),
      otherwise: yup.string(),
    }),
    maxirestAuthorization: yup.mixed().when('integrationMaxirest', {
      is: true,
      then: yup.string().required(t('schema-commerce-form-maxirestAuthorization-required')),
      otherwise: yup.string(),
    }),
  });

export const EditForm = (t: TFunction): any =>
  yup.object().shape({
    name: yup.string().required(t('schema-commerce-form-name-required')),
    code: yup
      .string()
      .required(t('schema-commerce-form-code-required'))
      .min(6, t('schema-commerce-form-code-min'))
      .max(36, t('schema-commerce-form-code-max'))
      .matches(/^\S*$/, t('schema-commerce-form-code-matches')),
    address: yup.string().required(t('schema-commerce-form-address-required')),
    email: yup.string().email(t('schema-commerce-form-email-email')).required(t('schema-commerce-form-email-required')),
    phone: yup
      .string()
      .required(t('schema-commerce-form-phone-required'))
      .matches(/([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/, t('schema-commerce-form-phone-matches')),
    categories: yup.array().of(yup.string()).min(1, t('schema-commerce-form-categories-min')),
    type: yup.string().required(t('schema-commerce-form-type-required')),
    logo: imageValidation('Logo', false, t),
    banner: imageValidation('Banner', true, t),
    cloverBanner: imageValidation('Clover Banner', false, t),
    enabled: enabledValidation(t),
    integrationMaxirest: enabledValidation(t),
    hideLogoOnList: enabledValidation(t),
    maxirestStoreId: yup.mixed().when('integrationMaxirest', {
      is: true,
      then: yup.string().required(t('schema-commerce-form-maxirestStoreId-required')),
      otherwise: yup.string(),
    }),
    maxirestPartner: yup.mixed().when('integrationMaxirest', {
      is: true,
      then: yup.string().required(t('schema-commerce-form-maxirestPartner-required')),
      otherwise: yup.string(),
    }),
    maxirestAuthorization: yup.mixed().when('integrationMaxirest', {
      is: true,
      then: yup.string().required(t('schema-commerce-form-maxirestAuthorization-required')),
      otherwise: yup.string(),
    }),
    commerceEditor: yup.string().required(t('schema-commerce-form-commerce-editor-required')),
  });
