import * as yup from 'yup';
import { enabledValidation } from 'utils/yup';
import { TFunction } from 'i18next';

export default (t: TFunction): any =>
  yup.object({
    deliveryEnabled: enabledValidation(t),
    pickUpEnabled: enabledValidation(t),
    isLocalDelivery: enabledValidation(t),
    showPhoneField: enabledValidation(t),
    isPhoneFieldRequired: yup.mixed().when('showPhoneField', {
      is: true,
      then: enabledValidation(t),
    }),
    areas: yup.array(),
  });
