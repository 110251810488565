import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
} from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import PaginationData from 'types/models/PaginationData';
import FiservQrSubsidiarySalesStand from 'types/models/FiservQrSubsidiarySalesStand';
import EditFiservQrSubsidiaryConfigContext from 'contexts/EditFiservQrSubsidiaryConfigContext';
import {
  getFiservQrSubsidiarySaleStands,
  deleteFiservQrSubsidiarySalesStand,
  findCloverPointsByAssignedSubsidiary,
} from 'services/fiservQrServices';
import ConfirmDialog from 'components/ConfirmDialog';

export default (): JSX.Element => {
  const history = useHistory();
  const [paginationFiservQrSubsidiarySalesStand, setPaginationFiservQrSubsidiarySalesStand] =
    useState<PaginationData<FiservQrSubsidiarySalesStand> | null>(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [fiservQrSubsidiarySalesStandIdToDelete, setFiservQrSubsidiarySalesStandIdToDelete] = React.useState<
    string | null
  >(null);
  const {
    selectedFiservQrSubsidiaryConfig,
    setSelectedFiservQrSubsidiarySalesStand,
    setPossibleCloverPointsToAssignToSaleStands,
  } = React.useContext(EditFiservQrSubsidiaryConfigContext);
  const { t } = useTranslation();

  const goToPage = (url: string): void => {
    history.push(url);
  };

  const onCloseDialog = () => {
    setFiservQrSubsidiarySalesStandIdToDelete(null);
  };

  React.useEffect(() => {
    loadData();
  }, []);

  React.useEffect(() => {
    if (selectedFiservQrSubsidiaryConfig) {
      loadData();
    } else {
      history.push('/private/admin/payments/fiserv-qr');
    }
  }, []);

  const loadData = React.useCallback(async (page?: number) => {
    if (!selectedFiservQrSubsidiaryConfig) return;
    const paginationData = await getFiservQrSubsidiarySaleStands(selectedFiservQrSubsidiaryConfig.id, page);
    if (paginationData) setPaginationFiservQrSubsidiarySalesStand(paginationData);
    const possibleCloverPoints = await findCloverPointsByAssignedSubsidiary(selectedFiservQrSubsidiaryConfig.id);
    if (possibleCloverPoints) setPossibleCloverPointsToAssignToSaleStands(possibleCloverPoints);
  }, []);

  const onDeleteFiservQrSubsidiarySalesConfig = async () => {
    if (selectedFiservQrSubsidiaryConfig && fiservQrSubsidiarySalesStandIdToDelete) {
      await deleteFiservQrSubsidiarySalesStand(
        selectedFiservQrSubsidiaryConfig?.id,
        fiservQrSubsidiarySalesStandIdToDelete,
      );
      history.go(0);
    }
  };

  const renderEditButton = (fiservQrSubsidiarySalesStand: FiservQrSubsidiarySalesStand): JSX.Element => {
    return (
      <IconButton
        color="inherit"
        onClick={() => {
          setSelectedFiservQrSubsidiarySalesStand(fiservQrSubsidiarySalesStand);
          history.push('sales-stands/edit');
        }}
      >
        <Edit />
      </IconButton>
    );
  };

  const renderDeleteButton = (fiservQrSubsidiarySalesStand: FiservQrSubsidiarySalesStand | null): JSX.Element => {
    return (
      <IconButton
        color="inherit"
        onClick={() => {
          if (!fiservQrSubsidiarySalesStand) return;
          setOpenDialog(true);
          setFiservQrSubsidiarySalesStandIdToDelete(fiservQrSubsidiarySalesStand.id);
        }}
      >
        <Delete />
      </IconButton>
    );
  };

  const renderContent = (paginationData: FiservQrSubsidiarySalesStand[]): JSX.Element => {
    return (
      <>
        {paginationData.map((fiservQrSubsidiarySalesStand: FiservQrSubsidiarySalesStand) => (
          <TableRow key={fiservQrSubsidiarySalesStand.id}>
            <TableCell component="th" scope="row">
              {fiservQrSubsidiarySalesStand.name}
            </TableCell>
            {fiservQrSubsidiarySalesStand.cloverPoint && (
              <TableCell component="th" scope="row">
                {fiservQrSubsidiarySalesStand.cloverPoint.description}
              </TableCell>
            )}
            <TableCell>{renderEditButton(fiservQrSubsidiarySalesStand)}</TableCell>
            <TableCell>{renderDeleteButton(fiservQrSubsidiarySalesStand)}</TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <div>
      <ConfirmDialog
        open={openDialog && fiservQrSubsidiarySalesStandIdToDelete !== null}
        setOpen={setOpenDialog}
        onConfirm={onDeleteFiservQrSubsidiarySalesConfig}
        onCancel={onCloseDialog}
      />
      <div className="vertical-bottom-space">
        <Button variant="contained" color="primary" fullWidth onClick={() => goToPage('sales-stands/create')}>
          {`${t('listFiservQrSubsidiarySaleStandConfigs-page-create')} ${selectedFiservQrSubsidiaryConfig?.name}`}
        </Button>
      </div>
      <div className="vertical-bottom-space">
        <Button variant="contained" color="secondary" fullWidth onClick={() => history.go(-1)}>
          Back
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('listFiservQrSubsidiarySaleStandConfigs-page-name')}</TableCell>
              <TableCell>{t('listFiservQrSubsidiarySaleStandConfigs-page-clover-point')}</TableCell>
              <TableCell>{t('listFiservQrSubsidiarySaleStandConfigs-page-edit')}</TableCell>
              <TableCell>{t('listFiservQrSubsidiarySaleStandConfigs-page-delete')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginationFiservQrSubsidiarySalesStand?.data ? (
              renderContent(paginationFiservQrSubsidiarySalesStand.data)
            ) : (
              <TableRow>
                <TableCell component="th" scope="row">
                  {t('listCloverPoints-page-noPoints')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {paginationFiservQrSubsidiarySalesStand && (
        <TablePagination
          component="div"
          count={paginationFiservQrSubsidiarySalesStand.total}
          page={paginationFiservQrSubsidiarySalesStand.currentPage - 1}
          onPageChange={(_, page: number) => {
            loadData(page + 1);
          }}
          rowsPerPage={paginationFiservQrSubsidiarySalesStand.perPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to} ${t('pagination-of')} ${count !== -1 ? count : '0'}`;
          }}
        />
      )}
    </div>
  );
};
