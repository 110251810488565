/* eslint @typescript-eslint/explicit-module-boundary-types: off */
import axiosInstance, { axiosMultipart } from './axios';
import { parseCommerces } from './commerceServices';
import App from 'types/models/App';
import PaginationData from 'types/models/PaginationData';
import Venue from 'types/models/Venue';
import VenuePaymentConfig from 'types/models/VenuePaymentConfig';
import FactoryWidget from 'types/models/FactoryWidget';
import SNSConfig from 'types/models/SNSConfig';
import VenueDeliveryConfig from 'types/models/VenueDeliveryConfig';
import VenueSectionType from 'types/enums/VenueSectionType';
import { parseBanner, parseBanners } from './bannerServices';
import { parseImages } from './imageServices';
import { parseLocalDeliveryAreas } from 'services/localDeliveryAreasServices';
import VenueSection from 'types/models/VenueSection';
import VenueImageType from 'types/enums/VenueImageType';
import Banner from 'types/models/Banner';

export const getVenues = async (page?: number): Promise<PaginationData<Venue> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `venues?page=${page ?? 1}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseVenues(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getVenuesByCommerce = async (): Promise<Venue[] | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: '/commerces/get/current-venues',
    });
    return parseVenues(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getApps = async (): Promise<App[] | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: 'apps/all',
    });
    return parseApps(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getAllVenues = async (): Promise<Venue[] | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: 'venues/get/all',
    });
    return parseVenues(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getVenuesCommercesProducts = async (): Promise<Venue[] | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: 'venues/get/all/products',
    });
    return parseVenues(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeVenue = async (formData: FormData): Promise<Venue | null> => {
  try {
    const { data } = await axiosMultipart({
      method: 'post',
      url: 'venues',
      data: formData,
    });
    return parseVenue(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getVenue = async (venueId: string): Promise<Venue | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `venues/${venueId}`,
    });
    return parseVenue(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateVenue = async (venueId: string, formData: FormData): Promise<Venue | null> => {
  try {
    const { data } = await axiosMultipart({
      method: 'post',
      url: `venues/${venueId}`,
      data: formData,
    });
    return parseVenue(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateVenuePaymentConfig = async (
  venueId: string,
  editVenueConfigForm: VenuePaymentConfig,
): Promise<VenuePaymentConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'put',
      url: `venues/${venueId}/payment-configs`,
      data: editVenueConfigForm,
    });
    return parseVenuePaymentConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateVenueFactoryWidgets = async (
  venueId: string,
  factoryWidgets: FactoryWidget[],
): Promise<FactoryWidget[] | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'put',
      url: `venues/${venueId}/factory-widgets`,
      data: {
        factoryWidgets,
      },
    });
    return parseVenueFactoryWidgets(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateVenueSNSConfig = async (
  venueId: string,
  snsConfig: SNSConfig,
): Promise<VenuePaymentConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'put',
      url: `venues/${venueId}/sns-configs`,
      data: snsConfig,
    });
    return parseVenuePaymentConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateVenueDeliveryConfig = async (
  venueId: string,
  editVenueConfigForm: VenueDeliveryConfig,
): Promise<VenueDeliveryConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'put',
      url: `venues/${venueId}/delivery-configs`,
      data: editVenueConfigForm,
    });
    return parseVenueDeliveryConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const parseAllVenueSectionTypes = (): any[] => {
  return Object.keys(VenueSectionType)
    .filter((item) => Number(Number(item)) !== VenueSectionType.Banner)
    .map((item) => {
      switch (Number(item)) {
        case VenueSectionType.Promotion:
          return {
            id: Number(item),
            description: 'Promociones',
          };
        case VenueSectionType.Commerce:
          return {
            id: Number(item),
            description: 'Comercios',
          };
        case VenueSectionType.Sparx:
          return {
            id: Number(item),
            description: 'Trivia',
          };
        case VenueSectionType.Ticket:
          return {
            id: Number(item),
            description: 'Ticket',
          };
        case VenueSectionType.Discount:
          return {
            id: Number(item),
            description: 'Descuentos',
          };
        case VenueSectionType.Download:
          return {
            id: Number(item),
            description: 'Descarga',
          };
        case VenueSectionType.Betting:
          return {
            id: Number(item),
            description: 'Apuestas',
          };
        default:
          return;
      }
    })
    .filter((item) => item !== undefined);
};

export const parseVenueSectionType = (type: VenueSectionType, sectionBanner?: Banner): string => {
  switch (Number(type)) {
    case VenueSectionType.Promotion:
      return 'Promociones';
    case VenueSectionType.Commerce:
      return 'Comercios';
    case VenueSectionType.Sparx:
      return 'Trivia';
    case VenueSectionType.Ticket:
      return 'Ticket';
    case VenueSectionType.Discount:
      return 'Descuentos';
    case VenueSectionType.Download:
      return 'Descarga';
    case VenueSectionType.Betting:
      return 'Apuestas';
    case VenueImageType.Banner:
      return sectionBanner?.title ?? 'Banner';
    default:
      return '';
  }
};

const parseVenues = (venues: any): Venue[] => {
  return venues.map((venue: any): Venue => parseVenue(venue));
};

export const parseVenue = (venue: any): Venue => {
  return {
    id: venue.id,
    name: venue.name,
    code: venue.code,
    sparxDescription: venue.sparx_description,
    sparxLink: venue.sparx_link,
    ticketDescription: venue.ticket_description,
    ticketLink: venue.ticket_link,
    ticketImageDescription: venue.ticket_image_description,
    enabled: venue.enabled === 1,
    useDifferentIconSet: venue.use_different_icon_set === 1,
    language: venue.language,
    order: venue.order_priority,
    billingRange: venue.billing_range,
    timeZoneIdentifier: venue.local_timezone_identifier,
    wallioUrl: venue.wallio_url ?? undefined,
    wallioTitle: venue.wallio_title ?? undefined,
    appColor: venue.app_color ?? undefined,
    appSecondaryColor: venue.app_secondary_color ?? undefined,
    commerces: venue.commerces ? parseCommerces(venue.commerces) : undefined,
    images: venue.images ? parseImages(venue.images) : undefined,
    paymentConfig: venue.payment_config ? parseVenuePaymentConfig(venue.payment_config) : undefined,
    factoryWidgets: venue.factory_widgets ? parseVenueFactoryWidgets(venue.factory_widgets) : undefined,
    bettingTitle: venue.betting_widget_title ?? undefined,
    bettingUrl: venue.betting_widget_url ?? undefined,
    twitterConfig: venue.twitter_config_id ?? undefined,
    deliveryConfig: venue.delivery_config ? parseVenueDeliveryConfig(venue.delivery_config) : undefined,
    apps: venue.apps ? parseApps(venue.apps) : undefined,
    editorId: venue.editor_id ?? undefined,
    entityIdentificator: venue.entity_identificator ?? undefined,
    banners: venue.banners ? parseBanners(venue.banners) : undefined,
    sections: venue.sections ? parseSections(venue.sections) : undefined,
  };
};

const parseApps = (apps: any): App[] => {
  return apps.map((app: any): App => parseApp(app));
};

const parseApp = (app: any): App => {
  return {
    id: app.id,
    description: app.description,
  };
};

const parseVenuePaymentConfig = (paymentConfig: any): VenuePaymentConfig => {
  return {
    id: paymentConfig.id,
    priorityOverCommerce: paymentConfig.priority_over_commerce,
    cashEnabled: paymentConfig.cash_enabled,
    balanceEnabled: paymentConfig.balance_enabled,
    activeProvider: paymentConfig.active_payment_provider_id,
    firstDataConfigId: paymentConfig.first_data_config_id,
    mpConfigId: paymentConfig.mp_config_id,
    mpCheckoutConfigId: paymentConfig.mp_checkout_config_id,
    mpPointConfigId: paymentConfig.mp_point_config_id,
    cloverPointConfigId: paymentConfig.clover_point_config_id,
    izipayConfigId: paymentConfig.izipay_config_id,
    fiservQrConfigId: paymentConfig.fiserv_qr_subsidiary_config_id,
  };
};

const parseVenueFactoryWidgets = (factoryWidgets: any): FactoryWidget[] => {
  return factoryWidgets.map(
    (factoryWidgetItem: any): FactoryWidget => ({
      id: factoryWidgetItem.id,
      factoryWidgetLink: factoryWidgetItem.factory_widget,
      height: factoryWidgetItem.height,
    }),
  );
};

const parseVenueDeliveryConfig = (deliveryConfig: any): VenueDeliveryConfig => {
  return {
    id: deliveryConfig.id,
    deliveryEnabled: deliveryConfig.delivery_enabled === 1,
    pickUpEnabled: deliveryConfig.pick_up_enabled === 1,
    isLocalDelivery: deliveryConfig.is_local_delivery === 1,
    priorityOverCommerce: deliveryConfig.priority_over_commerce === 1,
    showPhoneField: deliveryConfig.show_phone_field === 1,
    isPhoneFieldRequired: deliveryConfig.is_phone_field_required === 1,
    areas: deliveryConfig.areas ? parseLocalDeliveryAreas(deliveryConfig.areas) : [],
  };
};

const parseSections = (sections: any): VenueSection<any>[] => {
  return sections.map(
    (section: any): VenueSection<any> => ({
      id: section.id,
      type: section.pivot.venue_section_type_id,
      order: section.order,
      banner: section.banner ? parseBanner(section.banner) : undefined,
    }),
  );
};
