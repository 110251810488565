import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialog';
import { updateMPPoint } from '../services/mercadoPagoServices';
import MPPointForm from './schemas/MPPointForm';
import MPPointFormProps from '../types/forms/MPPointFormProps';
import EditMPPointConfigContext from 'contexts/EditMPPointConfigContext';

export default ({ mpPoint }: MPPointFormProps): JSX.Element => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const { selectedMPPointConfig } = React.useContext(EditMPPointConfigContext);
  const { t } = useTranslation();

  const onConfirm = async (): Promise<void> => {
    try {
      if (!selectedMPPointConfig) return;
      const data = await updateMPPoint(selectedMPPointConfig.id, { ...formik.values });

      if (data) {
        history.push('/private/admin/payments/mercado-pago-point/posnets');
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      id: mpPoint?.id ?? '',
      description: mpPoint?.description ?? '',
      operatingMode: mpPoint?.operatingMode ?? '',
      isPaired: mpPoint?.isPaired ?? false,
    },
    validationSchema: MPPointForm(t),
    onSubmit: async () => {
      setOpenDialog(true);
    },
  });

  return (
    <div>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="description"
            name="description"
            type="string"
            label={t('mpConfig-form-description')}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          />
        </div>
        <div className="vertical-bottom-space">
          <Button color="primary" variant="contained" fullWidth type="submit">
            {`${t('mpConfig-form-config-edit')} `}
          </Button>
        </div>
      </form>
    </div>
  );
};
