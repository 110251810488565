import { TFunction } from 'react-i18next';
import * as yup from 'yup';

export default (t: TFunction): any =>
  yup.object({
    description: yup.string().required(t('schema-CloverPointConfig-form-description-required')),
    deviceAddress: yup.string(),
    isPaired: yup.boolean().required(t('schema-CloverPoint-form-device-is-paired-required')),
    acceptsCash: yup.boolean().required(t('schema-CloverPoint-form-device-accepts-cash-required')),
  });
