import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
} from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { getFiservQrSubsidiaryConfigs, deleteFiservQrSubsidiaryConfig } from 'services/fiservQrServices';
import PaginationData from 'types/models/PaginationData';
import EditFiservQrSubsidiaryConfigContext from 'contexts/EditFiservQrSubsidiaryConfigContext';
import FiservQrSubsidiaryConfig from 'types/models/FiservQrSubsidiaryConfig';
import ConfirmDialog from 'components/ConfirmDialog';

export default (): JSX.Element => {
  const history = useHistory();
  const [paginationFiservQrSubsidiaryConfig, setPaginationFiservQrSubsidiaryConfigs] =
    useState<PaginationData<FiservQrSubsidiaryConfig> | null>(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [fiservQrSubsidiaryIdToDelete, setFiservQrSubsidiaryIdToDelete] = React.useState<string | null>(null);
  const { setSelectedFiservQrSubsidiaryConfig } = React.useContext(EditFiservQrSubsidiaryConfigContext);
  const { t } = useTranslation();

  const goToPage = (url: string): void => {
    history.push(url);
  };

  React.useEffect(() => {
    loadData();
  }, []);

  const loadData = React.useCallback(async (page?: number) => {
    const paginationData = await getFiservQrSubsidiaryConfigs(page);
    if (paginationData) setPaginationFiservQrSubsidiaryConfigs(paginationData);
  }, []);

  const onDeleteFiservQrSubsidiaryConfig = async () => {
    if (fiservQrSubsidiaryIdToDelete) {
      await deleteFiservQrSubsidiaryConfig(fiservQrSubsidiaryIdToDelete);
      history.go(0);
    }
  };

  const onCloseDialog = () => {
    setFiservQrSubsidiaryIdToDelete(null);
  };

  const renderEditButton = (fiservQrSubsidiaryConfig: FiservQrSubsidiaryConfig): JSX.Element => {
    return (
      <IconButton
        color="inherit"
        onClick={() => {
          setSelectedFiservQrSubsidiaryConfig(fiservQrSubsidiaryConfig);
          history.push('fiserv-qr/edit');
        }}
      >
        <Edit />
      </IconButton>
    );
  };

  const renderDeleteButton = (fiservQrSubsidiaryConfig: FiservQrSubsidiaryConfig | null): JSX.Element => {
    return (
      <IconButton
        color="inherit"
        onClick={() => {
          if (!fiservQrSubsidiaryConfig) return;
          setOpenDialog(true);
          setFiservQrSubsidiaryIdToDelete(fiservQrSubsidiaryConfig.id);
        }}
      >
        <Delete />
      </IconButton>
    );
  };

  const renderContent = (paginationData: FiservQrSubsidiaryConfig[]): JSX.Element => {
    return (
      <>
        {paginationData.map((fiservQrSubsidiaryConfig: FiservQrSubsidiaryConfig) => (
          <TableRow key={fiservQrSubsidiaryConfig.id}>
            <TableCell component="th" scope="row">
              {fiservQrSubsidiaryConfig.name}
            </TableCell>
            <TableCell>{renderEditButton(fiservQrSubsidiaryConfig)}</TableCell>
            <TableCell>{renderDeleteButton(fiservQrSubsidiaryConfig)}</TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <div>
      <ConfirmDialog
        open={openDialog && fiservQrSubsidiaryIdToDelete !== null}
        setOpen={setOpenDialog}
        onConfirm={onDeleteFiservQrSubsidiaryConfig}
        onCancel={onCloseDialog}
      />
      <div className="vertical-bottom-space">
        <Button variant="contained" color="primary" fullWidth onClick={() => goToPage('fiserv-qr/create')}>
          {t('listFiservQrSubsidiaryConfigs-page-create')}
        </Button>
      </div>
      <div className="vertical-bottom-space">
        <Button variant="contained" color="secondary" fullWidth onClick={() => history.go(-1)}>
          Back
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('listFiservQrSubsidiaryConfigs-page-name')}</TableCell>
              <TableCell>{t('listFiservQrSubsidiaryConfigs-page-edit')}</TableCell>
              <TableCell>{t('listFiservQrSubsidiaryConfigs-page-delete')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginationFiservQrSubsidiaryConfig?.data ? (
              renderContent(paginationFiservQrSubsidiaryConfig.data)
            ) : (
              <TableRow>
                <TableCell component="th" scope="row">
                  {t('listFiservQrSubsidiaryConfigs-page-noConfigs')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {paginationFiservQrSubsidiaryConfig && (
        <TablePagination
          component="div"
          count={paginationFiservQrSubsidiaryConfig.total}
          page={paginationFiservQrSubsidiaryConfig.currentPage - 1}
          onPageChange={(_, page: number) => {
            loadData(page + 1);
          }}
          rowsPerPage={paginationFiservQrSubsidiaryConfig.perPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to} ${t('pagination-of')} ${count !== -1 ? count : '0'}`;
          }}
        />
      )}
    </div>
  );
};
