/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axiosInstance, { axiosMultipart } from './axios';
import PaginationData from 'types/models/PaginationData';
import Commerce from 'types/models/Commerce';
import CommerceCategory from 'types/models/CommerceCategory';
import CommercePaymentConfig from 'types/models/CommercePaymentConfig';
import CommerceDeliveryConfig from 'types/models/CommerceDeliveryConfig';
import CommerceType from 'types/models/CommerceType';
import { parseImages } from './imageServices';
import { parseLocalDeliveryAreas } from 'services/localDeliveryAreasServices';
import Product from 'types/models/Product';

export const getCommercesWithPagination = async (page?: number): Promise<PaginationData<Commerce> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `commerces?page=${page ?? 1}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseCommerces(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const searchCommercesByUser = async (
  searchTerm: string,
  page?: number,
): Promise<PaginationData<Commerce> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `commerces/search/by-editor?page=${page ?? 1}&commerceName=${searchTerm}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseCommerces(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const searchCommercesByVenueEditor = async (
  searchTerm: string,
  page?: number,
): Promise<PaginationData<Commerce> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `venues/search/commerces/by-editor?page=${page ?? 1}&commerceName=${searchTerm}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseCommerces(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getCommercesByVenueEditor = async (page?: number): Promise<PaginationData<Commerce> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `venues/get/commerces/by-venue-editor?page=${page ?? 1}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseCommerces(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getCommerces = async (): Promise<Commerce[] | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: 'commerce/all',
    });
    return parseCommerces(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getCommerce = async (commerceId: string): Promise<Commerce | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `commerces/${commerceId}`,
    });
    return parseCommerce(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getCommerceCategories = async (): Promise<CommerceCategory[] | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: 'commerce-categories/all',
    });
    return parseCommerceCategories(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeCommerce = async (formData: FormData): Promise<Commerce | null> => {
  try {
    const { data } = await axiosMultipart({
      method: 'post',
      url: 'commerces',
      data: formData,
    });

    return parseCommerce(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateCommerce = async (commerceId: string, formData: FormData): Promise<Commerce | null> => {
  try {
    const { data } = await axiosMultipart({
      method: 'post',
      url: `commerces/${commerceId}`,
      data: formData,
    });
    return parseCommerce(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getCommerceCategoriesWithPagination = async (
  page?: number,
): Promise<PaginationData<CommerceCategory> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `commerce-categories?page=${page ?? 1}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseCommerceCategories(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getCommerceCategory = async (categoryId: string): Promise<CommerceCategory | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `commerce-categories/${categoryId}`,
    });
    return parseCommerceCategory(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeCommerceCategory = async (formData: FormData): Promise<CommerceCategory | null> => {
  try {
    const { data } = await axiosMultipart({
      method: 'post',
      url: 'commerce-categories',
      data: formData,
    });
    return parseCommerceCategory(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateCommerceCategory = async (
  categoryId: string,
  formData: FormData,
): Promise<CommerceCategory | null> => {
  try {
    const { data } = await axiosMultipart({
      method: 'post',
      url: `commerce-categories/${categoryId}?_method=put`,
      data: formData,
    });
    return parseCommerceCategory(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getAllCommerceTypes = async (): Promise<CommerceType[] | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: 'commerce-types/all',
    });
    return parseCommerceTypes(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const cloneCommerce = async (commerceDataId: string) => {
  try {
    await axiosInstance({
      method: 'post',
      url: `commerces/${commerceDataId}/clone`,
    });
  } catch (error) {
    console.log(error);
  }
};
export const deleteCommerce = async (commerceDataId: string) => {
  try {
    await axiosInstance({
      method: 'delete',
      url: `commerces/${commerceDataId}`,
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateCommercePaymentConfig = async (
  commerceId: string,
  editCommerceConfigForm: CommercePaymentConfig,
): Promise<CommercePaymentConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'put',
      url: `commerces/${commerceId}/payment-configs`,
      data: editCommerceConfigForm,
    });
    return parseCommercePaymentConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateCommerceDeliveryConfig = async (
  commerceId: string,
  editCommerceConfigForm: CommerceDeliveryConfig,
): Promise<CommerceDeliveryConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'put',
      url: `commerces/${commerceId}/delivery-configs`,
      data: editCommerceConfigForm,
    });
    return parseCommerceDeliveryConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const parseCommerces = (commerces: any): Commerce[] => {
  return commerces.map((commerce: any): Commerce => parseCommerce(commerce));
};

export const parseCommerce = (commerce: any): Commerce => {
  return {
    id: commerce.id,
    name: commerce.name,
    code: commerce.code,
    address: commerce.address,
    email: commerce.email,
    phone: commerce.phone,
    hideLogoOnList: commerce.hide_logo_on_list === 1,
    type: commerce.type ? parseCommerceType(commerce.type) : undefined,
    enabled: commerce.enabled === 1,
    integrationMaxirest: commerce.integration_maxirest === 1,
    editorId: commerce.editor_id,
    maxirestStoreId: commerce.maxirest_store_id,
    maxirestPartner: commerce.maxirest_partner,
    maxirestAuthorization: commerce.maxirest_authorization,
    categories: commerce.categories ? parseCommerceCategories(commerce.categories) : undefined,
    images: commerce.images ? parseImages(commerce.images) : undefined,
    paymentConfig: commerce.payment_config ? parseCommercePaymentConfig(commerce.payment_config) : undefined,
    deliveryConfig: commerce.delivery_config ? parseCommerceDeliveryConfig(commerce.delivery_config) : undefined,
    products: commerce.products ? parseItems(commerce.products) : undefined,
  };
};
const parseItems = (products: any): Product[] => {
  return products.map((product: any) => parseProduct(product));
};
const parseProduct = (product: any) => {
  return {
    id: product.id,
    name: product.name,
    description: product.description,
    enabled: product.enabled,
    isClothing: product.is_clothing,
    isSuggested: product.is_suggested,
    isPromotion: product.is_promotion,
    price: product.price,
    stock: product.stock,
    estimatedPreparationTime: product.estimated_preparation_time ?? undefined,
  };
};
const parseCommerceCategories = (categories: any): CommerceCategory[] => {
  return categories.map((category: any): CommerceCategory => parseCommerceCategory(category));
};

const parseCommerceTypes = (types: any): CommerceType[] => {
  return types.map((type: any): CommerceType => parseCommerceType(type));
};

const parseCommerceCategory = (commerceCategory: any): CommerceCategory => {
  return {
    id: commerceCategory.id,
    description: commerceCategory.description,
    images: commerceCategory.images ? parseImages(commerceCategory.images) : undefined,
  };
};

const parseCommercePaymentConfig = (paymentConfig: any): CommercePaymentConfig => {
  return {
    id: paymentConfig.id,
    cashEnabled: paymentConfig.cash_enabled,
    activeProvider: paymentConfig.active_payment_provider_id,
    firstDataConfigId: paymentConfig.first_data_config_id,
    mpConfigId: paymentConfig.mp_config_id,
    mpCheckoutConfigId: paymentConfig.mp_checkout_config_id,
    mpPointConfigId: paymentConfig.mp_point_config_id,
    cloverPointConfigId: paymentConfig.clover_point_config_id,
    izipayConfigId: paymentConfig.izipay_config_id,
    fiservQrConfigId: paymentConfig.fiserv_qr_subsidiary_config_id,
  };
};

const parseCommerceDeliveryConfig = (deliveryConfig: any): CommerceDeliveryConfig => {
  return {
    id: deliveryConfig.id,
    deliveryEnabled: deliveryConfig.delivery_enabled === 1,
    pickUpEnabled: deliveryConfig.pick_up_enabled === 1,
    isLocalDelivery: deliveryConfig.is_local_delivery === 1,
    showPhoneField: deliveryConfig.show_phone_field === 1,
    isPhoneFieldRequired: deliveryConfig.is_phone_field_required === 1,
    areas: deliveryConfig.areas ? parseLocalDeliveryAreas(deliveryConfig.areas) : [],
  };
};

const parseCommerceType = (type: any): CommerceType => {
  return {
    id: type.id,
    description: type.description,
  };
};
