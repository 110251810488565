import * as yup from 'yup';
import { TFunction } from 'i18next';
import { descriptionValidation, imageValidation } from 'utils/yup';

export const CreateForm = (t: TFunction): any =>
  yup.object().shape({
    description: descriptionValidation(t),
    logo: imageValidation(t('schema-commerceCategory-form-logo'), true, t),
  });

export const EditForm = (t: TFunction): any =>
  yup.object().shape({
    description: descriptionValidation(t),
    logo: imageValidation(t('schema-commerceCategory-form-logo'), false, t),
  });
