import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { Button, TextField, FormControlLabel, Switch, Typography, InputAdornment } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ImageInput from 'components/ImageInput';
import SelectField from 'components/SelectField';
import ConfirmDialog from 'components/ConfirmDialog';
import ProductFormProps from 'types/forms/ProductFormProps';
import Product, { ClothingSizeTypeStock } from 'types/models/Product';
import ProductCategory from 'types/models/ProductCategory';
import { CreateForm, EditForm } from './schemas/ProductForm';
import {
  getProductCategories,
  getClothingSizeTypes,
  storeProduct,
  updateProduct,
  deleteProduct,
  getAllSuggestedProducts,
} from 'services/productServices';
import EditCommerceContext from 'contexts/EditCommerceContext';
import ProductImageTypes from 'types/enums/ProductImageType';
import SuggestedProductTypes from 'types/enums/SuggestedProductType';
import ClothingSizeType from 'types/models/ClothingSizeType';

export default ({ product }: ProductFormProps): JSX.Element => {
  const history = useHistory();
  const [categories, setCategories] = React.useState<ProductCategory[] | null>(null);
  const [products, setProducts] = React.useState<Product[] | null>(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [onDeleteProduct, setOnDeleteProduct] = React.useState(false);
  const [productClothingSizeTypes, setProductClothingSizeTypes] = React.useState<ClothingSizeTypeStock[]>([]);
  const { selectedCommerce } = React.useContext(EditCommerceContext);
  const logo = product?.images?.find((image) => image.type === ProductImageTypes.Logo);
  const banner = product?.images?.find((image) => image.type === ProductImageTypes.PromotionBanner);
  const cloverLogo = product?.images?.find((image) => image.type === ProductImageTypes.CloverLogo);
  const { t } = useTranslation();

  const clothingSizeTypesOptions = (clothingSizes: ClothingSizeType[]) => {
    const options = product && product.clothingSizeStocks.length > 0 ? product.clothingSizeStocks : [];

    clothingSizes.map((clothingSize) => {
      const option = options.find(({ id }) => id === clothingSize.id);
      if (!option) {
        options.push({ ...clothingSize, stock: 0 });
      }
    });

    setProductClothingSizeTypes(options);
  };

  const showClothingSizeTypesInputs = () => {
    return productClothingSizeTypes.map((item, index) => {
      return (
        <TextField
          key={index}
          margin="dense"
          fullWidth
          name={item.description}
          value={item.stock}
          label={item.description}
          onChange={(e) => {
            handleStock(e, item);
          }}
        />
      );
    });
  };

  const handleStock = (e: any, item: any) => {
    e.preventDefault();
    let result = productClothingSizeTypes; // copy state
    result = result.map((el) => {
      // map array to replace the old stock with the new one
      if (el.description === item.description) el.stock = e.target.value ? parseInt(e.target.value) : 0;
      return el;
    });
    setProductClothingSizeTypes([...result]);
  };

  React.useEffect(() => {
    const getCategories = async () => {
      const categories = await getProductCategories();
      setCategories(categories);
    };

    const getAllclothingSizeTypes = async () => {
      const clothingSizes = await getClothingSizeTypes();
      clothingSizeTypesOptions(clothingSizes);
    };

    const getAllProducts = async (id: string) => {
      const products = await getAllSuggestedProducts(id);
      if (!products) return;
      setProducts(products);
    };
    getCategories();
    getAllclothingSizeTypes();
    if (selectedCommerce) {
      getAllProducts(selectedCommerce.id);
    }
  }, []);

  const onCancel = (): void => {
    formik.setSubmitting(false);
    if (onDeleteProduct) {
      setOnDeleteProduct(false);
    }
  };

  const onConfirm = async (): Promise<void> => {
    if (!formik.values.category) return;
    const formData = new FormData();
    formData.append('name', formik.values.name);
    formData.append('description', formik.values.description);
    formData.append('price', formik.values.price.toString());
    formData.append('estimatedPreparationTime', formik.values.estimatedPreparationTime.toString());
    formData.append('stock', formik.values.stock.toString());
    formData.append('minimunStock', formik.values.minimunStock.toString());
    formData.append('isPromotion', formik.values.isPromotion.toString());
    formData.append('isClothing', formik.values.isClothing ? '1' : '0');
    formData.append('isSuggested', formik.values.isSuggested ? '1' : '0');
    formData.append('showInList', formik.values.showInList ? '1' : '0');
    formData.append('category', formik.values.category);
    formData.append('logo', formik.values.logo as unknown as File);
    formData.append('banner', formik.values.promotionBanner as unknown as File);
    if (formik.values.cloverLogo) formData.append('cloverLogo', formik.values.cloverLogo as unknown as File);

    if (formik.values.isSuggested) {
      formData.append('suggestedType', formik.values.suggestedType.toString());
    }
    if (formik.values.isSuggested && formik.values.suggestedType === 1) {
      formData.append('minimumAmount', formik.values.minimumAmount.toString());
    }
    if (formik.values.isSuggested && formik.values.suggestedType === 2) {
      formData.append('suggestedProductsApplied', JSON.stringify(formik.values.suggestedProductsApplied));
    }

    if (formik.values.isPromotion) {
      formData.append('promotionBannerBackgroundColor', formik.values.promotionBannerBackgroundColor);
      formData.append('promotionBannerColorTitle', formik.values.promotionBannerColorTitle);
    }
    if (selectedCommerce?.integrationMaxirest) {
      formData.append('maxirestCode', formik.values.maxirestCode);
    }
    if (formik.values.isClothing) {
      formData.append('clothingSizeStocks', JSON.stringify(productClothingSizeTypes));
    }
    try {
      let result: Product | null = null;
      if (!selectedCommerce) return;
      if (product) {
        formData.append('enabled', formik.values.enabled.toString());
        formData.append('commerceId', selectedCommerce.id);
        result = await updateProduct(product.id, formData);
      } else {
        result = await storeProduct(selectedCommerce.id, formData);
      }
      if (result) {
        history.go(-1);
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: product?.name ?? '',
      description: product?.description ?? '',
      price: product?.price ?? '',
      estimatedPreparationTime: product?.estimatedPreparationTime ?? '',
      maxirestCode: product?.maxirestCode ?? '',
      logo: logo?.data ?? null,
      category: product?.category?.id ?? '',
      enabled: product?.enabled ?? true,
      stock: product?.stock ?? '',
      minimunStock: product?.minimunStock ?? '',
      isPromotion: product?.isPromotion ?? false,
      isClothing: product?.isClothing ?? false,
      isSuggested: product?.isSuggested ?? false,
      suggestedType: product?.suggestedType ?? '',
      suggestedProductsApplied: product?.suggestedProductsApplied ?? '',
      showInList: product?.showInList ?? true,
      minimumAmount: product?.minimumAmount ?? '',
      promotionBannerBackgroundColor: product?.promotionBannerBackgroundColor ?? '',
      promotionBannerColorTitle: product?.promotionBannerColorTitle ?? '',
      promotionBanner: banner?.data ?? null,
      cloverLogo: cloverLogo?.data ?? null,
    },

    validationSchema: () => {
      if (!selectedCommerce) return;
      const integrationMaxirest = selectedCommerce.integrationMaxirest;
      if (integrationMaxirest === undefined) return;
      if (product) {
        return EditForm(integrationMaxirest, t);
      } else {
        return CreateForm(integrationMaxirest, t);
      }
    },
    onSubmit: () => {
      setOpenDialog(true);
    },
  });

  const categoriesForSelect = (categories: ProductCategory[]) => {
    return categories.map((category) => {
      return {
        label: category.description,
        value: category.id,
      };
    });
  };

  const productsForSelect = (products: any) => {
    return products.map((product: any) => ({
      label: product.name,
      value: product.id,
    }));
  };

  const deleteProductByCommerce = async (id: string) => {
    formik.setSubmitting(true);
    await deleteProduct(id);
    formik.setSubmitting(false);
    history.go(-1);
  };

  const compareColors = (): boolean => {
    if (!formik.values.isPromotion) return false;
    if (formik.values.promotionBannerBackgroundColor === '') return false;
    if (formik.values.promotionBannerColorTitle === '') return false;
    if (formik.values.promotionBannerBackgroundColor === formik.values.promotionBannerColorTitle) return true;

    return false;
  };

  return (
    <>
      <ConfirmDialog
        open={openDialog}
        setOpen={setOpenDialog}
        onConfirm={onDeleteProduct ? deleteProductByCommerce : onConfirm}
        onCancel={onCancel}
        onConfirmArgs={product && onDeleteProduct ? [product.id] : undefined}
      />
      <form onSubmit={formik.handleSubmit}>
        <TextField
          margin="dense"
          fullWidth
          id="name"
          name="name"
          label={t('productForm-form-name')}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="description"
          name="description"
          label={t('productForm-form-description')}
          value={formik.values.description}
          onChange={formik.handleChange}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="price"
          name="price"
          label={t('productForm-form-price')}
          value={formik.values.price}
          onChange={formik.handleChange}
          error={formik.touched.price && Boolean(formik.errors.price)}
          helperText={formik.touched.price && formik.errors.price}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="estimatedPreparationTime"
          name="estimatedPreparationTime"
          label={t('productForm-form-estimatedPreparationTime')}
          value={formik.values.estimatedPreparationTime}
          InputProps={{
            endAdornment: <InputAdornment position="end">Minutos</InputAdornment>,
          }}
          onChange={formik.handleChange}
          error={formik.touched.estimatedPreparationTime && Boolean(formik.errors.estimatedPreparationTime)}
          helperText={formik.touched.estimatedPreparationTime && formik.errors.estimatedPreparationTime}
          disabled={formik.isSubmitting}
        />
        {selectedCommerce?.integrationMaxirest && (
          <TextField
            margin="dense"
            fullWidth
            id="maxirestCode"
            name="maxirestCode"
            label={t('productForm-form-maxirestCode')}
            value={formik.values.maxirestCode}
            onChange={formik.handleChange}
            error={formik.touched.maxirestCode && Boolean(formik.errors.maxirestCode)}
            helperText={formik.touched.maxirestCode && formik.errors.maxirestCode}
            disabled={formik.isSubmitting}
          />
        )}
        <FormControlLabel
          id="isPromotion"
          name="isPromotion"
          control={<Switch checked={formik.values.isPromotion} onChange={formik.handleChange} />}
          label={t('productForm-form-isPromotion')}
          disabled={formik.isSubmitting}
        />
        {product && (
          <FormControlLabel
            id="enabled"
            name="enabled"
            control={<Switch checked={formik.values.enabled} onChange={formik.handleChange} />}
            label={t('productForm-form-enabled')}
            disabled={formik.isSubmitting}
          />
        )}
        {formik.values.isPromotion && (
          <>
            <TextField
              margin="dense"
              fullWidth
              id="promotionBannerBackgroundColor"
              name="promotionBannerBackgroundColor"
              label={t('productForm-form-promotionBanner-backgroundColor')}
              value={formik.values.promotionBannerBackgroundColor}
              onChange={formik.handleChange}
              error={
                formik.touched.promotionBannerBackgroundColor && Boolean(formik.errors.promotionBannerBackgroundColor)
              }
              helperText={formik.touched.promotionBannerBackgroundColor && formik.errors.promotionBannerBackgroundColor}
              disabled={formik.isSubmitting}
            />
            <TextField
              margin="dense"
              fullWidth
              id="promotionBannerColorTitle"
              name="promotionBannerColorTitle"
              label={t('productForm-form-promotionBanner-colorTitle')}
              value={formik.values.promotionBannerColorTitle}
              onChange={formik.handleChange}
              error={formik.touched.promotionBannerColorTitle && Boolean(formik.errors.promotionBannerColorTitle)}
              helperText={formik.touched.promotionBannerColorTitle && formik.errors.promotionBannerColorTitle}
              disabled={formik.isSubmitting}
            />
            {compareColors() && <Typography color="error">{t('productForm-form-promotionBanner-error')}</Typography>}
          </>
        )}
        <FormControlLabel
          id="isClothing"
          name="isClothing"
          control={<Switch checked={formik.values.isClothing} onChange={formik.handleChange} />}
          label={t('productForm-form-isClothing')}
          disabled={formik.isSubmitting}
        />
        <FormControlLabel
          id="isSuggested"
          name="isSuggested"
          control={<Switch checked={formik.values.isSuggested} onChange={formik.handleChange} />}
          label={t('productForm-form-isSuggested')}
          disabled={formik.isSubmitting}
        />
        {formik.values.isSuggested && (
          <SelectField
            name="suggestedType"
            placeholder={t('productForm-form-suggested-placeholder')}
            label={t('productForm-form-suggested-label')}
            value={formik.values.suggestedType}
            options={[
              {
                label: 'Monto mínimo',
                value: SuggestedProductTypes.minimumAmount,
              },
              {
                label: 'Productos',
                value: SuggestedProductTypes.products,
              },
            ]}
            isMulti={false}
            setFieldValue={formik.setFieldValue}
            onBlur={formik.handleBlur}
            touched={formik.touched.suggestedType}
            error={formik.errors.suggestedType}
            isClearable={formik.values.isSuggested}
            backspaceRemovesValue={false}
            isDisabled={formik.isSubmitting}
            isSearchable={true}
          />
        )}
        {products && formik.values.isSuggested && formik.values.suggestedType === SuggestedProductTypes.products && (
          <SelectField
            name="suggestedProductsApplied"
            placeholder={t('schema-product-form-suggested-applied-placeholder')}
            label={t('schema-product-form-suggested-applied-label')}
            value={formik.values.suggestedProductsApplied}
            options={productsForSelect(products)}
            isMulti={true}
            setFieldValue={formik.setFieldValue}
            onBlur={formik.handleBlur}
            touched={formik.touched.suggestedProductsApplied}
            error={formik.errors.suggestedProductsApplied}
            isClearable={formik.values.isSuggested}
            backspaceRemovesValue={false}
            isDisabled={formik.isSubmitting}
            isSearchable={true}
          />
        )}
        {formik.values.isSuggested && formik.values.suggestedType === SuggestedProductTypes.minimumAmount && (
          <>
            <TextField
              margin="dense"
              fullWidth
              id="minimumAmount"
              name="minimumAmount"
              label={t('productForm-form-mininum-amount')}
              InputProps={{
                endAdornment: <InputAdornment position="end">{t('productForm-form-pesos')}</InputAdornment>,
              }}
              value={formik.values.minimumAmount}
              onChange={formik.handleChange}
              error={formik.touched.minimumAmount && Boolean(formik.errors.minimumAmount)}
              helperText={formik.touched.minimumAmount && formik.errors.minimumAmount}
              disabled={formik.isSubmitting}
            />
          </>
        )}
        <FormControlLabel
          id="showInList"
          name="showInList"
          control={<Switch checked={formik.values.showInList} onChange={formik.handleChange} />}
          label={t('productForm-form-showInList')}
          disabled={formik.isSubmitting}
        />
        {formik.values.isClothing ? (
          <>
            <div>{t('clothing-size-stock')}</div>
            {showClothingSizeTypesInputs()}
          </>
        ) : (
          <>
            <TextField
              margin="dense"
              fullWidth
              id="stock"
              name="stock"
              label={t('productForm-form-current-stock')}
              value={formik.values.stock}
              InputProps={{
                endAdornment: <InputAdornment position="end">{t('productForm-form-units')}</InputAdornment>,
              }}
              onChange={formik.handleChange}
              error={formik.touched.stock && Boolean(formik.errors.stock)}
              helperText={formik.touched.stock && formik.errors.stock}
              disabled={formik.isSubmitting}
            />
            <TextField
              margin="dense"
              fullWidth
              id="minimunStock"
              name="minimunStock"
              label={t('productForm-form-mininum-stock')}
              value={formik.values.minimunStock}
              InputProps={{
                endAdornment: <InputAdornment position="end">{t('productForm-form-units')}</InputAdornment>,
              }}
              onChange={formik.handleChange}
              error={formik.touched.minimunStock && Boolean(formik.errors.minimunStock)}
              helperText={formik.touched.minimunStock && formik.errors.minimunStock}
              disabled={formik.isSubmitting}
            />
          </>
        )}

        <ImageInput
          label={`${formik.values.logo || logo ? t('productForm-form-logo-edit') : t('productForm-form-logo-create')} `}
          name="logo"
          isSubmitting={formik.isSubmitting}
          value={formik.values.logo}
          setFieldValue={formik.setFieldValue}
          touched={formik.touched.logo}
          errors={formik.errors.logo}
          preview={logo?.preview}
        />

        <div className="user-message">{t('productForm-form-logo-size')}</div>
        {formik.values.isPromotion && (
          <>
            <ImageInput
              label={`${
                formik.values.promotionBanner || banner
                  ? t('productForm-form-banner-edit')
                  : t('productForm-form-banner-create')
              } `}
              name="promotionBanner"
              isSubmitting={formik.isSubmitting}
              value={formik.values.promotionBanner}
              setFieldValue={formik.setFieldValue}
              touched={formik.touched.promotionBanner}
              errors={formik.errors.promotionBanner}
              preview={banner?.preview}
            />
            <div className="user-message">{t('productForm-form-banner-size')}</div>
          </>
        )}
        <ImageInput
          label={`${
            formik.values.cloverLogo || cloverLogo
              ? t('productForm-form-cloverLogo-edit')
              : t('productForm-form-cloverLogo-create')
          } `}
          name="cloverLogo"
          isSubmitting={formik.isSubmitting}
          value={formik.values.cloverLogo}
          setFieldValue={formik.setFieldValue}
          touched={formik.touched.cloverLogo}
          errors={formik.errors.cloverLogo}
          preview={cloverLogo?.preview}
        />
        <div className="user-message">{t('productForm-form-cloverLogo-size')}</div>
        {categories && (
          <SelectField
            name="category"
            placeholder={t('productForm-form-category-placeholder')}
            label={t('productForm-form-category-label')}
            value={formik.values.category}
            options={categoriesForSelect(categories)}
            isMulti={false}
            setFieldValue={formik.setFieldValue}
            onBlur={formik.handleBlur}
            touched={formik.touched.category}
            error={formik.errors.category}
            // Si es de tipo indumentaria se puede limpiar la selección de categoría
            isClearable={formik.values.isClothing}
            backspaceRemovesValue={false}
            isDisabled={formik.isSubmitting}
            isSearchable={true}
          />
        )}

        <div className="footer">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            disabled={formik.isSubmitting || compareColors()}
          >
            {`${product ? t('productForm-form-product-edit') : t('productForm-form-product-create')} `}
          </Button>
        </div>
        {product && (
          <div className="footer">
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              type="submit"
              disabled={formik.isSubmitting}
              onClick={() => {
                setOpenDialog(true);
                setOnDeleteProduct(true);
              }}
            >
              {t('productForm-form-product-delete')}
            </Button>
          </div>
        )}
      </form>
    </>
  );
};
