import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import 'styles/pages/SelectPaymentTypePage.scss';

export default (): JSX.Element => {
  const history = useHistory();
  return (
    <div className="container">
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        className="payment-method-btn"
        onClick={() => history.push('/private/admin/payments/first-data')}
      >
        First Data
      </Button>
      <Button
        fullWidth
        variant="contained"
        className="payment-method-btn"
        color="primary"
        onClick={() => history.push('/private/admin/payments/izipay')}
      >
        Izipay
      </Button>
      <Button
        fullWidth
        variant="contained"
        className="payment-method-btn"
        color="primary"
        onClick={() => history.push('/private/admin/payments/mercado-pago')}
      >
        Mercado Pago
      </Button>
      <Button
        fullWidth
        variant="contained"
        className="payment-method-btn"
        color="primary"
        onClick={() => history.push('/private/admin/payments/mercado-pago-checkout')}
      >
        Mercado Pago Checkout
      </Button>
      <Button
        fullWidth
        variant="contained"
        className="payment-method-btn"
        color="primary"
        onClick={() => history.push('/private/admin/payments/mercado-pago-point')}
      >
        Mercado Pago Point
      </Button>
      <Button
        fullWidth
        variant="contained"
        className="payment-method-btn"
        color="secondary"
        onClick={() => history.push('/private/admin/payments/clover-point')}
      >
        Clover Point
      </Button>
      <Button
        fullWidth
        variant="contained"
        className="payment-method-btn"
        color="primary"
        onClick={() => history.push('/private/admin/payments/fiserv-qr')}
      >
        Fiserv QR
      </Button>
    </div>
  );
};
