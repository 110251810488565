import * as yup from 'yup';
import { TFunction } from 'react-i18next';
import { AnyObjectSchema } from 'yup';

export const CreateForm = (t: TFunction): AnyObjectSchema => {
  return yup.object().shape({
    name: yup.string().required(t('schema-FiservQrSubsidiarySaleStand-form-name-required')),
  });
};

export const EditForm = (t: TFunction, hasCloverPointsToAssign: boolean): any =>
  yup.object().shape({
    name: yup.string().required(t('schema-FiservQrSubsidiarySaleStand-form-name-required')),
    ...(hasCloverPointsToAssign && {
      cloverPointId: yup.string().required(t('schema-FiservQrSubsidiarySaleStand-form-cloverPoint-required')),
    }),
  });
