import * as yup from 'yup';
import moment from 'moment';
import { imageValidation } from 'utils/yup';
import { TFunction } from 'react-i18next';

const TODAY = moment().startOf('day');

export const CreateForm = (t: TFunction): any =>
  yup.object({
    title: yup
      .string()
      .required(t('schema-article-form-title-required'))
      .min(3, t('schema-article-form-title-min'))
      .max(64, t('schema-article-form-title-max')),
    description: yup
      .string()
      .required(t('schema-article-form-description-required'))
      .min(3, t('schema-article-form-description-min'))
      .max(191, t('schema-article-form-description-max')),
    body: yup
      .string()
      .required(t('schema-article-form-body-required'))
      .min(3, t('schema-article-form-body-min'))
      .max(30000, t('schema-article-form-body-max')),
    date: yup
      .date()
      .required(t('schema-article-form-date-required'))
      .test('AFTER_TODAY', t('schema-article-form-date-test'), (value) => moment(value).isSameOrAfter(TODAY)),
    banner: imageValidation('Banner', true, t),
    detailImages: yup.array().of(
      yup.object().shape({
        data: imageValidation('Detail', true, t),
        type: yup.number(),
      }),
    ),
  });

export const EditForm = (t: TFunction): any =>
  yup.object({
    title: yup
      .string()
      .required(t('schema-article-form-title-required'))
      .min(3, t('schema-article-form-title-min'))
      .max(64, t('schema-article-form-title-max')),
    description: yup
      .string()
      .required(t('schema-article-form-description-required'))
      .min(3, t('schema-article-form-description-min'))
      .max(191, t('schema-article-form-description-max')),
    body: yup
      .string()
      .required(t('schema-article-form-body-required'))
      .min(3, t('schema-article-form-body-min'))
      .max(30000, t('schema-article-form-body-max')),
    date: yup
      .date()
      .required(t('schema-article-form-date-required'))
      .test('AFTER_TODAY', t('schema-article-form-date-test'), (value) => moment(value).isSameOrAfter(TODAY)),
    banner: imageValidation('Banner', true, t),
    detailImages: yup.array().of(
      yup.object().shape({
        data: imageValidation('Detail', true, t),
        type: yup.number(),
      }),
    ),
  });
