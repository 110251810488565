import { TFunction } from 'react-i18next';
import * as yup from 'yup';
import { enabledValidation } from 'utils/yup';

export default (t: TFunction): any =>
  yup.object({
    description: yup.string().required(t('schema-CloverPointConfig-form-description-required')),
    accessToken: yup.string().required(t('schema-CloverPointConfig-form-access-token-required')),
    applicationId: yup.string().required(t('schema-CloverPointConfig-form-application-id-required')),
    cloverServer: yup
      .string()
      .url(t('schema-CloverPointConfig-form-clover-server-valid-url'))
      .required(t('schema-CloverPointConfig-form-clover-server-required')),
    merchantId: yup.string().required(t('schema-CloverPointConfig-form-merchant-id-required')),
    friendlyId: yup.string().required(t('schema-CloverPointConfig-form-friendly-id-required')),
    usesSitefApp: enabledValidation(t),
    merchantTaxId: yup.mixed().when('usesSitefApp', {
      is: true,
      then: yup.string().required(t('schema-CloverPointConfig-form-merchant-tax-id-required')),
      otherwise: yup.string(),
    }),
    isvTaxId: yup.mixed().when('usesSitefApp', {
      is: true,
      then: yup.string().required(t('schema-CloverPointConfig-form-isv-tax-id-required')),
      otherwise: yup.string(),
    }),
  });
