import * as yup from 'yup';
import { TFunction } from 'react-i18next';
import BenefitType from 'types/enums/BenefitType';
import { imageValidation } from 'utils/yup';

export const CreateForm = (t: TFunction): any =>
  yup.object().shape({
    totalAmountOrdersMin: yup
      .number()
      .positive(t('schema-benefit-form-number-not-zero'))
      .required(t('schema-benefit-form-total-amount-orders-min'))
      .test('maxDigitsAfterDecimal', t('schema-benefit-form-amount-orders'), (number: any) =>
        /^\d+(\.\d{1,2})?$/.test(number),
      ),
    totalAmountOrdersMax: yup
      .number()
      .positive(t('schema-benefit-form-number-not-zero'))
      .required(t('schema-benefit-form-total-amount-orders-max'))
      .test('maxDigitsAfterDecimal', t('schema-benefit-form-amount-orders'), (number: any) =>
        /^\d+(\.\d{1,2})?$/.test(number),
      ),
    totalCantOrdersMin: yup
      .number()
      .positive(t('schema-benefit-form-number-not-zero'))
      .required(t('schema-benefit-form-total-total_cant_orders_min')),
    totalCantOrdersMax: yup
      .number()
      .positive(t('schema-benefit-form-number-not-zero'))
      .required(t('schema-benefit-form-total-total_cant_orders_max')),
    benefitType: yup.number().required(t('schema-benefit-form-type')),
    benefitDescription: yup.string().required(t('schema-benefit-form-description')),
    benefitDiscountAmount: yup.mixed().when('benefitType', {
      is: BenefitType.DiscountBenefit,
      then: yup.string().required(t('schema-benefit-form-discount')),
    }),
    benefitLocation: yup.string().required(t('schema-benefit-form-location')),
    benefitLocationId: yup.string().required(t('schema-benefit-form-location-specific')),
    productBenefit: yup.mixed().when('benefitType', {
      is: BenefitType.ProductBenefit,
      then: yup.string().required(t('schema-benefit-form-product')),
    }),
    banner: imageValidation('Banner', false, t),
  });

export const EditForm = (t: TFunction): any =>
  yup.object().shape({
    totalAmountOrdersMin: yup
      .string()
      .required(t('schema-benefit-form-total-amount-orders-min'))
      .test('maxDigitsAfterDecimal', t('schema-benefit-form-amount-orders'), (number: any) =>
        /^\d+(\.\d{1,2})?$/.test(number),
      ),
    totalAmountOrdersMax: yup
      .string()
      .required(t('schema-benefit-form-total-amount-orders-max'))
      .test('maxDigitsAfterDecimal', t('schema-benefit-form-amount-orders'), (number: any) =>
        /^\d+(\.\d{1,2})?$/.test(number),
      ),
    totalCantOrdersMin: yup.number().required(t('schema-benefit-form-total-total_cant_orders_min')),
    totalCantOrdersMax: yup.number().required(t('schema-benefit-form-total-total_cant_orders_max')),
    benefitType: yup.number().required(t('schema-benefit-form-type')),
    benefitDescription: yup.string().required(t('schema-benefit-form-description')),
    benefitDiscountAmount: yup.mixed().when('benefitType', {
      is: BenefitType.DiscountBenefit,
      then: yup.string().required(t('schema-benefit-form-discount')),
    }),
    benefitLocation: yup.string().required(t('schema-benefit-form-location')),
    benefitLocationId: yup.string().required(t('schema-benefit-form-location-specific')),
    productBenefit: yup.mixed().when('benefitType', {
      is: BenefitType.ProductBenefit,
      then: yup.string().required(t('schema-benefit-form-product')),
    }),
    banner: imageValidation('Banner', false, t),
  });
