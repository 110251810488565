import * as yup from 'yup';
import { TFunction } from 'react-i18next';
import moment from 'moment';

export const AdminForm = (t: TFunction): any =>
  yup.object().shape({
    venueId: yup.string().required(t('schema-consumptionsReport-form-venueId-required')),
    commerceIds: yup.array().of(yup.string()).min(1, t('schema-consumptionsReport-form-commerceId-required')),
    dateFrom: yup.date().required(t('schema-consumptionsReport-form-dateFrom-required')),
    dateTo: yup
      .date()
      .when(
        'dateFrom',
        (dateFrom, schema) =>
          dateFrom && schema.min(moment(dateFrom).add(1, 'minute'), t('schema-consumptionsReport-form-dateTo-when')),
      )
      .required(t('schema-consumptionsReport-form-dateTo-required')),
  });

export const EditorForm = (t: TFunction): any =>
  yup.object().shape({
    venueId: yup.string().required(t('schema-consumptionsReport-form-venueId-required')),
    commerceIds: yup.array().of(yup.string()).min(1, t('schema-consumptionsReport-form-commerceId-required')),
    dateFrom: yup.date().required(t('schema-consumptionsReport-form-dateFrom-required')),
    dateTo: yup
      .date()
      .when(
        'dateFrom',
        (dateFrom, schema) =>
          dateFrom && schema.min(moment(dateFrom).add(1, 'minute'), t('schema-consumptionsReport-form-dateTo-when')),
      )
      .required(t('schema-consumptionsReport-form-dateTo-required')),
  });
