import React from 'react';
import { useHistory } from 'react-router-dom';
import FiservQrSubsidiarySaleStandForm from 'forms/FiservQrSubsidiarySaleStandForm';
import EditFiservQrSubsidiaryConfigContext from 'contexts/EditFiservQrSubsidiaryConfigContext';
import { getFiservQrSaleStandBySubsidiary } from 'services/fiservQrServices';
import FiservQrSubsidiarySalesStand from 'types/models/FiservQrSubsidiarySalesStand';

export default (): JSX.Element => {
  const history = useHistory();
  const { selectedFiservQrSubsidiaryConfig, selectedFiservQrSubsidiarySalesStand } = React.useContext(
    EditFiservQrSubsidiaryConfigContext,
  );
  const [completeFiservQrSubsidiarySalesStandConfig, setCompleteFiservQrSubsidiarySalesStandConfig] =
    React.useState<FiservQrSubsidiarySalesStand | null>(null);

  const load = React.useCallback(async (subsidiaryId: string, saleStandId: string) => {
    const fiservQrSubsidiarySalesStandConfig = await getFiservQrSaleStandBySubsidiary(subsidiaryId, saleStandId);
    if (!fiservQrSubsidiarySalesStandConfig) {
      history.push('/private/admin/payments/fiserv-qr');
      return;
    }
    setCompleteFiservQrSubsidiarySalesStandConfig(fiservQrSubsidiarySalesStandConfig);
  }, []);

  React.useEffect(() => {
    if (!selectedFiservQrSubsidiaryConfig || !selectedFiservQrSubsidiarySalesStand) {
      history.push('/private/admin/payments/fiserv-qr');
    } else {
      load(selectedFiservQrSubsidiaryConfig.id, selectedFiservQrSubsidiarySalesStand.id);
    }
  }, [selectedFiservQrSubsidiaryConfig]);

  if (!completeFiservQrSubsidiarySalesStandConfig) return <></>;
  return <FiservQrSubsidiarySaleStandForm config={completeFiservQrSubsidiarySalesStandConfig} />;
};
