import React from 'react';
import { Button, FormHelperText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ImageInputProps from 'types/components/ImageInputProps';
import 'styles/components/ImageInput.scss';

export default ({
  label,
  name,
  value,
  setFieldValue,
  isSubmitting,
  idealSize,
  touched,
  errors,
  preview,
}: ImageInputProps): JSX.Element => {
  const [thumb, setThumb] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const { t } = useTranslation();

  React.useEffect(() => {
    const reader = new FileReader();
    if (value) {
      reader.onloadend = () => {
        setLoading(false);
        setThumb(reader.result);
      };
      reader.readAsDataURL(value);
    } else if (preview) {
      setLoading(false);
      setThumb(preview);
    }
    return () => {
      reader.abort();
    };
  }, [value, preview]);

  const Thumb = (): JSX.Element | null => {
    if (loading) {
      return <p>{t('image-input-loading')}</p>;
    }
    return <img src={thumb} height={200} width={200} />;
  };

  return (
    <>
      {thumb && (
        <div className="thumb-container">
          <Thumb />
        </div>
      )}
      <Button fullWidth variant="contained" color="secondary" component="label" disabled={isSubmitting}>
        {label}
        <input
          id={name}
          name={name}
          type="file"
          hidden
          onChange={(event) => {
            const files = event?.currentTarget?.files;
            if (files && files[0]) setFieldValue(name, files[0]);
          }}
        />
      </Button>
      {thumb && (
        <div className="delete-thumb-image-btn-container">
          <Button
            variant="contained"
            fullWidth
            onClick={async () => {
              await setFieldValue(name, null);
              await setThumb(null);
            }}
          >
            {t('remove-image')}
          </Button>
        </div>
      )}

      <FormHelperText variant="outlined" error={true}>
        {touched ? errors : ''}
      </FormHelperText>
      {idealSize && (
        <div className="ideal-size-message">
          {`${t('image-input-image-size')} ${idealSize.width.toFixed(0)} x ${idealSize.height.toFixed(0)}`}
        </div>
      )}
    </>
  );
};
