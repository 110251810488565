import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialog';
import SelectField from 'components/SelectField';
import { storeFiservQrSubsidiarySaleStand, editFiservQrSubsidiarySaleStand } from 'services/fiservQrServices';
import EditFiservQrSubsidiaryConfigContext from 'contexts/EditFiservQrSubsidiaryConfigContext';
import { CreateForm, EditForm } from './schemas/FiservQrSubsidiarySaleStandForm';
import FiservQrSubsidiarySaleStandFormProps from 'types/forms/FiservQrSubsidiarySaleStandFormProps';
import CloverPoint from 'types/models/CloverPoint';

export default ({ config }: FiservQrSubsidiarySaleStandFormProps): JSX.Element => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const { selectedFiservQrSubsidiaryConfig, possibleCloverPointsToAssignToSaleStands } = React.useContext(
    EditFiservQrSubsidiaryConfigContext,
  );
  const { t } = useTranslation();
  const hasPossibleCloverPointsToAssign =
    possibleCloverPointsToAssignToSaleStands && possibleCloverPointsToAssignToSaleStands.length > 0 ? true : false;

  const onConfirm = async (): Promise<void> => {
    if (!selectedFiservQrSubsidiaryConfig) return;
    try {
      const data = config
        ? await editFiservQrSubsidiarySaleStand(selectedFiservQrSubsidiaryConfig?.id, formik.values)
        : await storeFiservQrSubsidiarySaleStand(selectedFiservQrSubsidiaryConfig?.id, formik.values);
      if (data) {
        history.push('/private/admin/payments/fiserv-qr');
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      id: config?.id ?? '',
      name: config?.name ?? '',
      cloverPointId: config?.cloverPoint?.id ?? undefined,
    },
    validationSchema: config ? EditForm(t, hasPossibleCloverPointsToAssign) : CreateForm(t),
    onSubmit: async () => {
      setOpenDialog(true);
    },
  });

  const cloverPointOptions = hasPossibleCloverPointsToAssign
    ? possibleCloverPointsToAssignToSaleStands?.map((cloverPointItem: CloverPoint) => ({
        value: cloverPointItem.id,
        label: `${cloverPointItem.description} - Serial: ${cloverPointItem.serial}`,
      }))
    : [];

  return (
    <div>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="name"
            name="name"
            type="string"
            label={t('fiservQrSubsidiarySaleStandConfig-form-name')}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </div>
        {config && (
          <SelectField
            name="cloverPointId"
            label={t('fiservQrSubsidiarySaleStandConfig-form-cloverPoints-label')}
            placeholder={t('fiservQrSubsidiarySaleStandConfig-form-cloverPoints-placerholder')}
            value={formik.values.cloverPointId}
            options={cloverPointOptions}
            isMulti={false}
            setFieldValue={formik.setFieldValue}
            onBlur={formik.handleBlur}
            touched={formik.touched.cloverPointId}
            error={formik.errors.cloverPointId}
            isClearable={true}
            backspaceRemovesValue={true}
            isDisabled={formik.isSubmitting}
            isSearchable={true}
          />
        )}
        <div className="vertical-bottom-space">
          <Button color="primary" variant="contained" fullWidth type="submit">
            {`${
              config
                ? t('fiservQrSubsidiarySaleStandConfig-form-edit')
                : t('fiservQrSubsidiarySaleStandConfig-form-create')
            }`}
          </Button>
        </div>
      </form>
    </div>
  );
};
