import * as yup from 'yup';
import moment from 'moment';
import { imageValidation } from 'utils/yup';
import { TFunction } from 'react-i18next';

const TODAY = moment().startOf('day');

export const CreateForm = (t: TFunction): any =>
  yup.object({
    name: yup
      .string()
      .required(t('schema-event-form-name-required'))
      .min(3, t('schema-event-form-name-min'))
      .max(64, t('schema-event-form-name-max')),
    description: yup
      .string()
      .required(t('schema-event-form-description-required'))
      .min(3, t('schema-event-form-description-min'))
      .max(191, t('schema-event-form-description-max')),
    eventStart: yup
      .date()
      .required(t('schema-event-form-eventStart-required'))
      .test('AFTER_TODAY', t('schema-event-form-eventStart-test'), (value) => moment(value).isAfter(TODAY, 'day')),
    eventEnd: yup
      .date()
      .required(t('schema-event-form-eventEnd-required'))
      .when(
        'eventStart',
        (eventStart, schema) => eventStart && schema.min(eventStart, t('schema-event-form-eventEnd-when')),
      ),
    banner: imageValidation('Logo', true, t),
  });

export const EditForm = (t: TFunction): any =>
  yup.object({
    name: yup
      .string()
      .required(t('schema-event-form-name-required'))
      .min(3, t('schema-event-form-name-min'))
      .max(64, t('schema-event-form-name-max')),
    description: yup
      .string()
      .required(t('schema-event-form-description-required'))
      .min(3, t('schema-event-form-description-min'))
      .max(191, t('schema-event-form-description-max')),
    eventStart: yup
      .date()
      .required(t('schema-event-form-eventStart-required'))
      .test('AFTER_TODAY', t('schema-event-form-eventStart-test'), (value) => moment(value).isAfter(TODAY, 'day')),
    eventEnd: yup
      .date()
      .required(t('schema-event-form-eventEnd-required'))
      .when(
        'eventStart',
        (eventStart, schema) => eventStart && schema.min(eventStart, t('schema-event-form-eventEnd-when')),
      ),
    banner: imageValidation('Banner', false, t),
  });
