import * as yup from 'yup';
import { MixedSchema } from 'yup/lib/mixed';
import { RequiredBooleanSchema } from 'yup/lib/boolean';
import { RequiredStringSchema } from 'yup/lib/string';
import { TFunction } from 'react-i18next';

export const MAX_FILE_SIZE = 2097152;
export const IMAGE_VALID_FILES = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/svg'];

export const regexColor = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;

export const imageValidation = (imageType: string, isRequired: boolean, t: TFunction): MixedSchema => {
  let mixed = yup
    .mixed()
    .test('FILE_SIZE', t('general-validations.file-size'), (value) => !value || value.size <= MAX_FILE_SIZE)
    .test(
      'FILE_TYPE',
      `${t('general-validations.file-must')} jpeg, png, jpg, gif o svg`,
      (value) => !value || IMAGE_VALID_FILES.includes(value.type),
    );
  if (isRequired) {
    mixed = mixed.required(t('general-validations.file-type-required', { imageType: imageType }));
  }
  return mixed;
};

export const maxirestCodeValidation = (codeIsRequired: boolean, t: TFunction): MixedSchema => {
  let mixed = yup.mixed();
  if (codeIsRequired) {
    mixed = mixed.required(t('general-validations.maxirest-code-required'));
  }
  return mixed;
};

export const enabledValidation = (t: TFunction): RequiredBooleanSchema<boolean | undefined> =>
  yup.boolean().required(t('general-validations.enabled'));

export const descriptionValidation = (t: TFunction): RequiredStringSchema<string | undefined> =>
  yup.string().required(t('general-validations.description'));

export const validateTimeZoneIdentifiers = (t: TFunction): MixedSchema => {
  const mixed = yup
    .mixed()
    .test('VALID_TIMEZONE', t('general-validations.valid-timezone'), (value) => {
      try {
        if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
          return false;
        }

        if (typeof value !== 'string') {
          return false;
        }

        // throws an error if timezone is not valid
        Intl.DateTimeFormat(undefined, { timeZone: value });
        return true;
      } catch (error) {
        return false;
      }
    })
    .required(t('general-validations.required-timezone'));
  return mixed;
};
