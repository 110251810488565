import * as yup from 'yup';
import { enabledValidation } from 'utils/yup';
import { TFunction } from 'i18next';

export default (t: TFunction): any =>
  yup.object({
    cashEnabled: enabledValidation(t),
    activeProvider: yup.number().positive(),
    firstDataConfigId: yup.string(),
    mpConfigId: yup.string(),
    mpCheckoutConfigId: yup.string(),
    mpPointConfigId: yup.string(),
    cloverPointConfigId: yup.string(),
    fiservQrConfigId: yup.string(),
  });
