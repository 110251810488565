import axiosInstance from './axios';
import PaginationData from 'types/models/PaginationData';
import FiservQrSubsidiaryConfig from 'types/models/FiservQrSubsidiaryConfig';
import FiservQrSubsidiarySalesStand from 'types/models/FiservQrSubsidiarySalesStand';
import CloverPoint from 'types/models/CloverPoint';
import { parseCloverPoint, parseCloverPoints } from './cloverServices';

export const getFiservQrSubsidiaryConfigs = async (
  page?: number,
): Promise<PaginationData<FiservQrSubsidiaryConfig> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `payments/fiserv-qr/subsidiaries?page=${page ?? 1}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseFiservQrSubsidiaryConfigs(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeFiservQrSubsidiaryConfig = async (
  fiservQrSubsidiaryConfig: FiservQrSubsidiaryConfig,
): Promise<FiservQrSubsidiaryConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: 'payments/fiserv-qr/subsidiaries',
      data: fiservQrSubsidiaryConfig,
    });
    return parseFiservQrSubsidiaryConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getFiservQrSubsidiaryConfig = async (
  fiservQrSubsidiaryConfigId: string,
): Promise<FiservQrSubsidiaryConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `payments/fiserv-qr/subsidiaries/${fiservQrSubsidiaryConfigId}`,
    });
    return parseFiservQrSubsidiaryConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const editFiservQrSubsidiaryConfig = async (
  fiservQrSubsidiaryConfigId: string,
  fiservQrSubsidiaryConfig: FiservQrSubsidiaryConfig,
): Promise<FiservQrSubsidiaryConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'put',
      url: `payments/fiserv-qr/subsidiaries/${fiservQrSubsidiaryConfigId}`,
      data: fiservQrSubsidiaryConfig,
    });
    return parseFiservQrSubsidiaryConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const deleteFiservQrSubsidiaryConfig = async (
  fiservQrSubsidiaryConfigId: string,
): Promise<FiservQrSubsidiaryConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'delete',
      url: `payments/fiserv-qr/subsidiaries/${fiservQrSubsidiaryConfigId}`,
    });
    return parseFiservQrSubsidiaryConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getFiservQrSubsidiarySaleStands = async (
  subsidiaryId: string,
  page?: number,
): Promise<PaginationData<FiservQrSubsidiarySalesStand> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `payments/fiserv-qr/subsidiaries/${subsidiaryId}/sale-stands?page=${page ?? 1}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseFiservQrSubsidiarySalesStands(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeFiservQrSubsidiarySaleStand = async (
  subsidiaryId: string,
  fiservQrSubsidiarySalesStand: FiservQrSubsidiarySalesStand,
): Promise<FiservQrSubsidiarySalesStand | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: `payments/fiserv-qr/subsidiaries/${subsidiaryId}/sale-stands`,
      data: fiservQrSubsidiarySalesStand,
    });
    return parseFiservQrSubsidiarySalesStand(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const editFiservQrSubsidiarySaleStand = async (
  subsidiaryId: string,
  fiservQrSubsidiaryConfig: FiservQrSubsidiarySalesStand,
): Promise<FiservQrSubsidiarySalesStand | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'put',
      url: `payments/fiserv-qr/subsidiaries/${subsidiaryId}/sale-stands/${fiservQrSubsidiaryConfig.id}`,
      data: fiservQrSubsidiaryConfig,
    });
    return parseFiservQrSubsidiarySalesStand(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getFiservQrSaleStandBySubsidiary = async (
  fiservQrSubsidiaryConfigId: string,
  fiservQrSubsidiarySalesStandId: string,
): Promise<FiservQrSubsidiarySalesStand | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `payments/fiserv-qr/subsidiaries/${fiservQrSubsidiaryConfigId}/sale-stands/${fiservQrSubsidiarySalesStandId}`,
    });
    return parseFiservQrSubsidiarySalesStand(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const deleteFiservQrSubsidiarySalesStand = async (
  subsidiaryId: string,
  fiservQrSubsidiarySalesStandId: string,
): Promise<FiservQrSubsidiarySalesStand | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'delete',
      url: `payments/fiserv-qr/subsidiaries/${subsidiaryId}/sale-stands/${fiservQrSubsidiarySalesStandId}`,
    });
    return parseFiservQrSubsidiarySalesStand(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const findCloverPointsByAssignedSubsidiary = async (subsidiaryId: string): Promise<CloverPoint[] | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `payments/fiserv-qr/clover-points/${subsidiaryId}`,
    });
    return parseCloverPoints(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const parseFiservQrSubsidiaryConfigs = (data: any): FiservQrSubsidiaryConfig[] => {
  return data.map((item: any): FiservQrSubsidiaryConfig => parseFiservQrSubsidiaryConfig(item));
};

const parseFiservQrSubsidiaryConfig = (data: any): FiservQrSubsidiaryConfig => {
  return {
    id: data.id,
    name: data.name,
    address: data.address,
    phone: data.phone,
    streetNumber: data.street_number,
    streetName: data.street_name,
    city: data.city,
    postalCode: data.postal_code,
    department: data.department,
    country: data.country,
    state: data.state,
  };
};

const parseFiservQrSubsidiarySalesStands = (data: any): FiservQrSubsidiarySalesStand[] => {
  return data.map((item: any): FiservQrSubsidiarySalesStand => parseFiservQrSubsidiarySalesStand(item));
};

const parseFiservQrSubsidiarySalesStand = (data: any): FiservQrSubsidiarySalesStand => {
  return {
    id: data.id,
    name: data.name,
    cloverPoint: data.clover_point ? parseCloverPoint(data.clover_point) : undefined,
  };
};
