import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { Button, FormControlLabel, Switch, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialog';
import ProductCategoriesFormProps from 'types/forms/ProductCategoriesFormProps';
import ProductCategory from 'types/models/ProductCategory';
import ProductCategoryForm from './schemas/productCategoryForm';
import { storeProductCategory, updateProductCategory } from 'services/productServices';
import ImageInput from 'components/ImageInput';

export default ({ productCategory }: ProductCategoriesFormProps): JSX.Element => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const { t } = useTranslation();
  const logo = productCategory?.image;

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const onConfirm = async (): Promise<void> => {
    try {
      let result: ProductCategory | null = null;
      const request = {
        id: productCategory?.id ?? '',
        description: formik.values.description,
        requiresIdentification: formik.values.requiresIdentification === true ? '1' : '0',
        logo: formik.values.logo ?? undefined,
      };

      if (productCategory) {
        result = await updateProductCategory(request);
      } else {
        result = await storeProductCategory(request);
      }
      if (result) {
        history.go(-1);
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      description: productCategory?.description ?? '',
      requiresIdentification: productCategory?.requiresIdentification ?? false,
      logo: logo?.data ?? null,
    },
    validationSchema: ProductCategoryForm(t),
    onSubmit: () => {
      setOpenDialog(true);
    },
  });

  return (
    <>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <TextField
          margin="dense"
          fullWidth
          id="description"
          name="description"
          label={t('productCategory-form-description')}
          value={formik.values.description}
          onChange={formik.handleChange}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
          disabled={formik.isSubmitting}
        />
        <FormControlLabel
          id="requiresIdentification"
          name="requiresIdentification"
          control={<Switch checked={formik.values.requiresIdentification} onChange={formik.handleChange} />}
          label={t('productCategory-form-requiresIdentification')}
          disabled={formik.isSubmitting}
        />
        <ImageInput
          label={`${
            formik.values.logo || logo ? t('productCategory-form-logo-edit') : t('productCategory-form-logo-create')
          } `}
          name="logo"
          isSubmitting={formik.isSubmitting}
          value={formik.values.logo}
          setFieldValue={formik.setFieldValue}
          touched={formik.touched.logo}
          errors={formik.errors.logo}
          preview={logo?.preview}
        />
        <div className="recommended-image-category-text">{t('general-suggestions.image-category-text')}</div>

        <div className="footer">
          <Button fullWidth variant="contained" color="primary" type="submit" disabled={formik.isSubmitting}>
            {`${
              productCategory ? t('productCategory-form-category-edit') : t('productCategory-form-category-create')
            } `}
          </Button>
        </div>
      </form>
    </>
  );
};
