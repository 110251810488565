import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { Box, Button, FormControlLabel, Switch } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CommerceDeliveryConfigFormProps from 'types/forms/CommerceDeliveryConfigFormProps';
import CommerceDeliveryConfig from 'types/models/CommerceDeliveryConfig';
import CommerceDeliveryConfigForm from './schemas/CommerceDeliveryConfigForm';
import ConfirmDialog from 'components/ConfirmDialog';
import AreasInput from 'components/AreasInput';
import { updateCommerceDeliveryConfig } from 'services/commerceServices';

export default ({ commerce }: CommerceDeliveryConfigFormProps): JSX.Element => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const { t } = useTranslation();

  const onConfirm = async (): Promise<void> => {
    if (!commerce) return;

    const dataCommerceDeliveryConfig: CommerceDeliveryConfig = {
      id: '',
      deliveryEnabled: formik.values.deliveryEnabled,
      pickUpEnabled: formik.values.pickUpEnabled,
      isLocalDelivery: formik.values.isLocalDelivery,
      showPhoneField: formik.values.showPhoneField,
      isPhoneFieldRequired: formik.values.isPhoneFieldRequired,
      areas: formik.values.areas,
    };

    try {
      const result = await updateCommerceDeliveryConfig(commerce.id, dataCommerceDeliveryConfig);
      if (result) {
        history.go(-1);
      } else {
        throw new Error();
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      deliveryEnabled: commerce.deliveryConfig?.deliveryEnabled ?? false,
      pickUpEnabled: commerce.deliveryConfig?.pickUpEnabled ?? false,
      isLocalDelivery: commerce.deliveryConfig?.isLocalDelivery ?? false,
      showPhoneField: commerce?.deliveryConfig?.showPhoneField ?? false,
      isPhoneFieldRequired: commerce?.deliveryConfig?.isPhoneFieldRequired ?? false,
      areas: commerce.deliveryConfig?.areas ?? [],
    },
    validationSchema: CommerceDeliveryConfigForm(),
    onSubmit: (): void => {
      setOpenDialog(true);
    },
  });

  return (
    <>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <FormControlLabel
            id="deliveryEnabled"
            name="deliveryEnabled"
            control={<Switch checked={formik.values.deliveryEnabled} onChange={formik.handleChange} />}
            label={t('commerceDeliveryConfig-form-deliveryEnabled')}
            disabled={formik.isSubmitting}
          />
        </Box>
        <Box>
          <FormControlLabel
            id="pickUpEnabled"
            name="pickUpEnabled"
            control={<Switch checked={formik.values.pickUpEnabled} onChange={formik.handleChange} />}
            label={t('commerceDeliveryConfig-form-pickUpEnabled')}
            disabled={formik.isSubmitting}
          />
        </Box>
        <Box>
          <FormControlLabel
            id="isLocalDelivery"
            name="isLocalDelivery"
            control={<Switch checked={formik.values.isLocalDelivery} onChange={formik.handleChange} />}
            label={t('commerceDeliveryConfig-form-isLocalDelivery')}
            disabled={formik.isSubmitting}
          />
        </Box>
        {formik.values.deliveryEnabled && (
          <Box>
            <FormControlLabel
              id="showPhoneField"
              name="showPhoneField"
              control={<Switch checked={formik.values.showPhoneField} onChange={formik.handleChange} />}
              label={t('commerceDeliveryConfig-form-showPhoneField')}
              disabled={formik.isSubmitting}
            />
            {formik.values.showPhoneField && (
              <FormControlLabel
                id="isPhoneFieldRequired"
                name="isPhoneFieldRequired"
                control={<Switch checked={formik.values.isPhoneFieldRequired} onChange={formik.handleChange} />}
                label={t('commerceDeliveryConfig-form-isPhoneFieldRequired')}
                disabled={formik.isSubmitting}
              />
            )}
          </Box>
        )}
        <AreasInput
          type={'Comercio'}
          name={'areas'}
          values={formik.values.areas}
          setFieldValue={formik.setFieldValue}
          isDisabled={formik.isSubmitting}
        />
        <Box marginTop="1rem">
          <Button fullWidth variant="contained" color="primary" type="submit" disabled={formik.isSubmitting}>
            {t('commerceDeliveryConfig-form-saveConfig')}
          </Button>
        </Box>
      </form>
    </>
  );
};
