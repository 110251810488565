import React from 'react';
import { useHistory } from 'react-router-dom';
import FiservQrSubsidiaryForm from 'forms/FiservQrSubsidiaryForm';
import EditFiservQrSubsidiaryConfigContext from 'contexts/EditFiservQrSubsidiaryConfigContext';
import { getFiservQrSubsidiaryConfig } from 'services/fiservQrServices';
import FiservQrSubsidiaryConfig from 'types/models/FiservQrSubsidiaryConfig';

export default (): JSX.Element => {
  const history = useHistory();
  const { selectedFiservQrSubsidiaryConfig } = React.useContext(EditFiservQrSubsidiaryConfigContext);
  const [completeFiservQrSubsidiaryConfig, setCompleteFiservQrSubsidiaryConfig] =
    React.useState<FiservQrSubsidiaryConfig | null>(null);

  const load = React.useCallback(async (id: string) => {
    const fiservQrSubsidiaryConfig = await getFiservQrSubsidiaryConfig(id);
    if (!fiservQrSubsidiaryConfig) {
      history.push('/private/admin/payments/fiserv-qr');
      return;
    }
    setCompleteFiservQrSubsidiaryConfig(fiservQrSubsidiaryConfig);
  }, []);

  React.useEffect(() => {
    if (!selectedFiservQrSubsidiaryConfig) {
      history.push('/private/admin/payments/fiserv-qr');
    } else {
      load(selectedFiservQrSubsidiaryConfig.id);
    }
  }, [selectedFiservQrSubsidiaryConfig]);

  if (!completeFiservQrSubsidiaryConfig) return <></>;
  return <FiservQrSubsidiaryForm config={completeFiservQrSubsidiaryConfig} />;
};
