import * as yup from 'yup';
import { imageValidation } from 'utils/yup';
import { TFunction } from 'react-i18next';

export const CreateForm = (t: TFunction): any =>
  yup.object({
    title: yup.string().required(t('schema-banner-form-title-required')),
    description: yup.string().required(t('schema-banner-form-description-required')),
    url: yup.string().url(t('schema-banner-form-url-valid')).required(t('schema-banner-form-url-required')),
    front: imageValidation(t('schema-banner-form-image'), true, t),
  });

export const EditForm = (t: TFunction): any =>
  yup.object({
    title: yup.string().required(t('schema-banner-form-title-required')),
    description: yup.string().required(t('schema-banner-form-description-required')),
    url: yup.string().url(t('schema-banner-form-url-valid')).required(t('schema-banner-form-url-required')),
    front: imageValidation(t('schema-banner-form-image'), false, t),
  });
