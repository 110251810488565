import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialog';
import { storeMPCheckoutConfig, updateMPCheckoutConfig } from './../services/mercadoPagoServices';
import MPCheckoutConfigForm from './schemas/MPCheckoutConfigForm';
import MPCheckoutConfigFormProps from '../types/forms/MPCheckoutConfigFormProps';

export default ({ config }: MPCheckoutConfigFormProps): JSX.Element => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const { t } = useTranslation();

  const onConfirm = async (): Promise<void> => {
    try {
      const data = config
        ? await updateMPCheckoutConfig({ ...formik.values })
        : await storeMPCheckoutConfig({ ...formik.values });
      if (data) {
        history.push('/private/admin/payments/mercado-pago-checkout');
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      id: config?.id ?? '',
      description: config?.description ?? '',
      currencyCode: config?.currencyCode ?? '',
      mpApiUrl: config?.mpApiUrl ?? '',
      publicKey: config?.publicKey ?? '',
      token: config?.token ?? '',
    },
    validationSchema: MPCheckoutConfigForm(t),
    onSubmit: async () => {
      setOpenDialog(true);
    },
  });

  return (
    <div>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="description"
            name="description"
            type="string"
            label={t('mpConfig-form-description')}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="currencyCode"
            name="currencyCode"
            type="string"
            label={t('mpChecktoutConfig-form-currency-code')}
            value={formik.values.currencyCode}
            onChange={formik.handleChange}
            error={formik.touched.currencyCode && Boolean(formik.errors.currencyCode)}
            helperText={formik.touched.currencyCode && formik.errors.currencyCode}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="mpApiUrl"
            name="mpApiUrl"
            type="url"
            label={t('cloverPoint-form-device-address')}
            value={formik.values.mpApiUrl}
            onChange={formik.handleChange}
            error={formik.touched.mpApiUrl && Boolean(formik.errors.mpApiUrl)}
            helperText={formik.touched.mpApiUrl && formik.errors.mpApiUrl}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="publicKey"
            name="publicKey"
            type="string"
            label={t('mpChecktoutConfig-form-public-key')}
            value={formik.values.publicKey}
            onChange={formik.handleChange}
            error={formik.touched.publicKey && Boolean(formik.errors.publicKey)}
            helperText={formik.touched.publicKey && formik.errors.publicKey}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="token"
            name="token"
            label={t('mpConfig-form-token')}
            type="string"
            value={formik.values.token}
            onChange={formik.handleChange}
            error={formik.touched.token && Boolean(formik.errors.token)}
            helperText={formik.touched.token && formik.errors.token}
          />
        </div>
        <div className="vertical-bottom-space">
          <Button color="primary" variant="contained" fullWidth type="submit">
            {`${config ? t('mpConfig-form-config-edit') : t('mpConfig-form-config-create')} `}
          </Button>
        </div>
      </form>
    </div>
  );
};
