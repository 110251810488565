import React from 'react';
import { Button, Typography, List, ListItem, TextField, Box } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { AddCircle, DeleteOutlineRounded } from '@material-ui/icons';
import VenueFactoryWidgetFormProps from 'types/forms/VenueFactoryWidgetFormProps';
import { updateVenueFactoryWidgets } from 'services/venuesServices';
import VenueFactoryWidgetForm from 'forms/schemas/VenueFactoryWidgetForm';
import ConfirmDialog from 'components/ConfirmDialog';
import 'styles/forms/VenueFactoryWidgetForm.scss';

export default ({ venue }: VenueFactoryWidgetFormProps): JSX.Element => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const { t } = useTranslation();

  const onConfirm = async (): Promise<void> => {
    if (!venue) return;

    const formikFactoryWidget = formik.values.factoryWidgets;

    try {
      const result = await updateVenueFactoryWidgets(venue.id, formikFactoryWidget);
      if (result) {
        history.go(-1);
      } else {
        throw new Error();
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      factoryWidgets: venue.factoryWidgets ?? [],
    },
    validationSchema: VenueFactoryWidgetForm(t),
    onSubmit: (): void => {
      setOpenDialog(true);
    },
  });

  return (
    <>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <div className="title-container">
          <Typography variant="h6" component="div">
            {`${t('venueFactoryWidget-form-title')}`}
          </Typography>
          <div className="add-factory-widget-container">
            <Button
              variant="contained"
              color="primary"
              endIcon={<AddCircle />}
              onClick={() => {
                formik.values.factoryWidgets.push({ id: '', factoryWidgetLink: '', height: 0 });
                formik.setFieldValue('factoryWidgets', [...formik.values.factoryWidgets]);
              }}
            >
              {t('venueFactoryWidget-form-add')}
            </Button>
          </div>
        </div>
        <List>
          {formik.values.factoryWidgets.map((factoryWidgetItem, index) => (
            <>
              <ListItem key={index}>
                <div className="factory-widget-link-container">
                  <TextField
                    margin="dense"
                    id="factoryWidget"
                    name="factoryWidget"
                    label={t('venueFactoryWidget-form-link')}
                    value={factoryWidgetItem.factoryWidgetLink}
                    onChange={(e) => {
                      formik.values.factoryWidgets[index].factoryWidgetLink = e.target.value.toString();
                      formik.setFieldValue('factoryWidgets', [...formik.values.factoryWidgets]);
                    }}
                    disabled={formik.isSubmitting}
                    fullWidth={true}
                  />
                </div>
                <TextField
                  margin="dense"
                  id="height"
                  name="height"
                  label={t('venueFactoryWidget-form-height')}
                  value={factoryWidgetItem.height}
                  type="number"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  onChange={(e) => {
                    formik.values.factoryWidgets[index].height = parseFloat(e.target.value);
                    formik.setFieldValue('factoryWidgets', [...formik.values.factoryWidgets]);
                  }}
                  disabled={formik.isSubmitting}
                  size="small"
                />
                <div className="factory-widget-buttons-container">
                  <div className="delete-button-element">
                    <Button
                      variant="outlined"
                      color="secondary"
                      endIcon={<DeleteOutlineRounded />}
                      onClick={() => {
                        formik.values.factoryWidgets.splice(index, 1);
                        formik.setFieldValue('factoryWidgets', [...formik.values.factoryWidgets]);
                      }}
                    >
                      {t('venueFactoryWidget-form-delete')}
                    </Button>
                  </div>
                </div>
              </ListItem>
            </>
          ))}
        </List>
        <div className="fields-required-alert">{t('general-suggestions.field-required')}</div>
        <Box marginTop="1rem">
          <Button fullWidth variant="contained" color="primary" type="submit" disabled={formik.isSubmitting}>
            {`${t('venueFactoryWidget-form-save')} `}
          </Button>
        </Box>
      </form>
    </>
  );
};
