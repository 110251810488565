/* eslint @typescript-eslint/explicit-module-boundary-types: off */
import axiosInstance from './axios';
import { parseUser } from './authServices';
import PaginationData from 'types/models/PaginationData';
import User from 'types/models/User';
import UserRoleType from 'types/enums/UserRoleType';
import UserData from 'types/requests/UserData';

export const getUsersWithPagination = async (page?: number): Promise<PaginationData<User> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `users?page=${page ?? 1}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseUsers(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};
export const getCommerceUsers = async (page?: number): Promise<PaginationData<User> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `/?page=${page ?? 1}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseUsers(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeUser = async (userData: UserData): Promise<User | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: 'users',
      data: userData,
    });
    return parseUser(data);
  } catch (error: any) {
    throw new Error(error.response.data.error);
  }
};

export const updateUser = async (userId: string, userData: UserData): Promise<User | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'put',
      url: `users/${userId}`,
      data: userData,
    });
    return parseUser(data);
  } catch (error: any) {
    throw new Error(error.response.data.error);
  }
};

export const getUser = async (userId: string): Promise<User | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `users/${userId}`,
    });
    return parseUser(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getUsersByEditorType = async (
  editorType: UserRoleType,
  currentEditor?: string,
): Promise<User[] | null> => {
  try {
    let url = `users/get/by-editor-type/${editorType}`;
    if (currentEditor) url = url.concat(`?userId=${currentEditor}`);
    const { data } = await axiosInstance({
      method: 'get',
      url: url,
    });
    return parseUsers(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const searchUsers = async (searchTerm: string, page?: number): Promise<PaginationData<User> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `users/search/by-editor?page=${page ?? 1}&userName=${searchTerm}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseUsers(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};
const parseUsers = (users: any): User[] => {
  return users.map((user: any): User => parseUser(user));
};
