import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { Box, Button, FormControlLabel, Switch } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  getProviders,
  getFDConfigs,
  getMPConfigs,
  getMPPointConfigs,
  getCloverPointConfigs,
  getIzipayConfigs,
  getMPCheckoutConfigs,
  getFiservQrConfigs,
} from 'services/paymentsServices';
import SelectField from 'components/SelectField';
import ConfirmDialog from 'components/ConfirmDialog';
import VenuePaymentConfigForm from './schemas/VenuePaymentConfigForm';
import VenuePaymentConfigFormProps from 'types/forms/VenuePaymentConfigFormProps';
import PaymentProvider from 'types/models/PaymentProvider';
import FirstDataConfig from 'types/models/FirstDataConfig';
import MercadoPagoConfig from 'types/models/MercadoPagoConfig';
import { updateVenuePaymentConfig } from 'services/venuesServices';
import IzipayConfig from 'types/models/IzipayConfig';
import MercadoPagoPointConfig from 'types/models/MercadoPagoPointConfig';
import CloverPointConfig from 'types/models/CloverPointConfig';
import MercadoPagoCheckoutConfig from 'types/models/MercadoPagoCheckoutConfig';
import FiservQrSubsidiaryConfig from 'types/models/FiservQrSubsidiaryConfig';

export default ({ venue }: VenuePaymentConfigFormProps): JSX.Element => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [providerList, setProviderList] = React.useState<PaymentProvider[]>([]);
  const [firstDataList, setFirstDataList] = React.useState<FirstDataConfig[]>([]);
  const [mpList, setMPList] = React.useState<MercadoPagoConfig[]>([]);
  const [mpCheckoutList, setMPCheckoutList] = React.useState<MercadoPagoCheckoutConfig[]>([]);
  const [MPPointList, setMPPointList] = React.useState<MercadoPagoPointConfig[]>([]);
  const [cloverPointList, setCloverPointList] = React.useState<CloverPointConfig[]>([]);
  const [izipayList, setIzipayList] = React.useState<IzipayConfig[]>([]);
  const [fiservQrSubsidiaryConfigList, setFiservQrSubsidiaryConfigList] = React.useState<
    FiservQrSubsidiaryConfig[] | []
  >([]);
  const { t } = useTranslation();

  React.useEffect(() => {
    async function getData() {
      const getFDConfigsResult = await getFDConfigs();
      setFirstDataList(getFDConfigsResult);
      const getMPConfigsResult = await getMPConfigs();
      setMPList(getMPConfigsResult);
      const getMPPConfigsResult = await getMPPointConfigs();
      setMPPointList(getMPPConfigsResult);
      const getMPCheckoutConfigsResult = await getMPCheckoutConfigs();
      setMPCheckoutList(getMPCheckoutConfigsResult);
      const getCPConfigsResult = await getCloverPointConfigs();
      setCloverPointList(getCPConfigsResult);
      const getIPConfigsResult = await getIzipayConfigs();
      setIzipayList(getIPConfigsResult);
      const getFiservQrConfigsResult = await getFiservQrConfigs();
      if (getFiservQrConfigsResult) setFiservQrSubsidiaryConfigList(getFiservQrConfigsResult);
      const getProvidersResult = await getProviders('venue');
      setProviderList(getProvidersResult);
    }

    getData();
  }, []);

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const onConfirm = async (): Promise<void> => {
    if (!venue) return;
    try {
      const data = await updateVenuePaymentConfig(venue.id, {
        id: '0',
        priorityOverCommerce: formik.values.priorityOverCommerce,
        cashEnabled: formik.values.cashEnabled,
        balanceEnabled: formik.values.balanceEnabled,
        activeProvider: formik.values.activeProvider,
        firstDataConfigId: formik.values.firstDataConfigId ?? undefined,
        mpConfigId: formik.values.mpConfigId ?? undefined,
        mpCheckoutConfigId: formik.values.mpCheckoutConfigId ?? undefined,
        mpPointConfigId: formik.values.mpPointConfigId ?? undefined,
        cloverPointConfigId: formik.values.cloverPointConfigId ?? undefined,
        izipayConfigId: formik.values.izipayConfigId ?? undefined,
        fiservQrConfigId: formik.values.fiservQrConfigId ?? undefined,
      });
      if (data) {
        history.go(-1);
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      priorityOverCommerce: venue?.paymentConfig?.priorityOverCommerce ?? false,
      cashEnabled: venue?.paymentConfig?.cashEnabled ?? false,
      balanceEnabled: venue?.paymentConfig?.balanceEnabled ?? false,
      activeProvider: venue?.paymentConfig?.activeProvider ?? undefined,
      firstDataConfigId: venue?.paymentConfig?.firstDataConfigId ?? undefined,
      mpConfigId: venue?.paymentConfig?.mpConfigId ?? undefined,
      mpCheckoutConfigId: venue?.paymentConfig?.mpCheckoutConfigId ?? undefined,
      mpPointConfigId: venue?.paymentConfig?.mpPointConfigId ?? undefined,
      cloverPointConfigId: venue?.paymentConfig?.cloverPointConfigId ?? undefined,
      izipayConfigId: venue?.paymentConfig?.izipayConfigId ?? undefined,
      fiservQrConfigId: venue?.paymentConfig?.fiservQrConfigId ?? undefined,
    },
    validationSchema: VenuePaymentConfigForm(t),
    onSubmit: async () => {
      setOpenDialog(true);
    },
  });

  const providersOptions = providerList.map((provider: PaymentProvider) => ({
    value: provider.id,
    label: provider.description,
  }));

  const firstDataOptions = firstDataList.map((fdItem: FirstDataConfig) => ({
    value: fdItem.id,
    label: fdItem.description,
  }));

  const mpOptions = mpList.map((mpItem: MercadoPagoConfig) => ({
    value: mpItem.id,
    label: mpItem.description,
  }));

  const mpCheckoutOptions = mpCheckoutList.map((mpCheckoutItem: MercadoPagoCheckoutConfig) => ({
    value: mpCheckoutItem.id,
    label: mpCheckoutItem.description,
  }));

  const mpPointOptions = MPPointList.map((mpItem: MercadoPagoPointConfig) => ({
    value: mpItem.id,
    label: mpItem.description,
  }));

  const cloverPointOptions = cloverPointList.map((cloverPoint: CloverPointConfig) => ({
    value: cloverPoint.id,
    label: cloverPoint.description,
  }));

  const izipayOptions = izipayList.map((izipayItem: IzipayConfig) => ({
    value: izipayItem.id,
    label: izipayItem.description,
  }));

  const fiservQrOptions = fiservQrSubsidiaryConfigList.map(
    (fiservQrSubsidiaryConfigItem: FiservQrSubsidiaryConfig) => ({
      value: fiservQrSubsidiaryConfigItem.id,
      label: fiservQrSubsidiaryConfigItem.name,
    }),
  );

  return (
    <>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <FormControlLabel
            id="priorityOverCommerce"
            name="priorityOverCommerce"
            control={<Switch checked={formik.values.priorityOverCommerce} onChange={formik.handleChange} />}
            label={t('venuePaymentConfig-form-priorityOverCommerce')}
            disabled={formik.isSubmitting}
          />
        </Box>
        <Box>
          <FormControlLabel
            id="cashEnabled"
            name="cashEnabled"
            control={<Switch checked={formik.values.cashEnabled} onChange={formik.handleChange} />}
            label={t('venuePaymentConfig-form-cashEnabled')}
            disabled={formik.isSubmitting}
          />
        </Box>
        <Box>
          <FormControlLabel
            id="balanceEnabled"
            name="balanceEnabled"
            control={<Switch checked={formik.values.balanceEnabled} onChange={formik.handleChange} />}
            label={t('venuePaymentConfig-form-balanceEnabled')}
            disabled={formik.isSubmitting}
          />
        </Box>
        <SelectField
          name="activeProvider"
          label={t('venuePaymentConfig-form-activeProvider-label')}
          placeholder={t('venuePaymentConfig-form-activeProvider-placeholder')}
          value={formik.values.activeProvider}
          options={providersOptions}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.activeProvider}
          error={formik.errors.activeProvider}
          isClearable={true}
          backspaceRemovesValue={true}
          isDisabled={formik.isSubmitting}
          isSearchable={false}
        />
        <SelectField
          name="firstDataConfigId"
          label={t('venuePaymentConfig-form-firstDataConfigId-label')}
          placeholder={t('venuePaymentConfig-form-firstDataConfigId-placeholder')}
          value={formik.values.firstDataConfigId}
          options={firstDataOptions}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.firstDataConfigId}
          error={formik.errors.firstDataConfigId}
          isClearable={true}
          backspaceRemovesValue={true}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
        <SelectField
          name="mpConfigId"
          label={t('venuePaymentConfig-form-mpConfigId-label')}
          placeholder={t('venuePaymentConfig-form-mpConfigId-placeholder')}
          value={formik.values.mpConfigId}
          options={mpOptions}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.mpConfigId}
          error={formik.errors.mpConfigId}
          isClearable={true}
          backspaceRemovesValue={true}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
        <SelectField
          name="mpCheckoutConfigId"
          label={t('venuePaymentConfig-form-mpCheckoutConfigId-label')}
          placeholder={t('venuePaymentConfig-form-mpCheckoutConfigId-placeholder')}
          value={formik.values.mpCheckoutConfigId}
          options={mpCheckoutOptions}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.mpCheckoutConfigId}
          error={formik.errors.mpCheckoutConfigId}
          isClearable={true}
          backspaceRemovesValue={true}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
        <SelectField
          name="mpPointConfigId"
          label={t('venuePaymentConfig-form-mpPointConfigId-label')}
          placeholder={t('venuePaymentConfig-form-mpPointConfigId-placeholder')}
          value={formik.values.mpPointConfigId}
          options={mpPointOptions}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.mpPointConfigId}
          error={formik.errors.mpPointConfigId}
          isClearable={true}
          backspaceRemovesValue={true}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
        <SelectField
          name="cloverPointConfigId"
          label={t('venuePaymentConfig-form-cloverPointConfigId-label')}
          placeholder={t('venuePaymentConfig-form-cloverPointConfigId-placeholder')}
          value={formik.values.cloverPointConfigId}
          options={cloverPointOptions}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.cloverPointConfigId}
          error={formik.errors.cloverPointConfigId}
          isClearable={true}
          backspaceRemovesValue={true}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
        <SelectField
          name="izipayConfigId"
          label={t('venuePaymentConfig-form-izipayConfigId-label')}
          placeholder={t('venuePaymentConfig-form-izipayConfigId-placeholder')}
          value={formik.values.izipayConfigId}
          options={izipayOptions}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.izipayConfigId}
          error={formik.errors.izipayConfigId}
          isClearable={true}
          backspaceRemovesValue={true}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
        <SelectField
          name="fiservQrConfigId"
          label={t('commercePaymentConfig-form-fiservQrConfigId-label')}
          placeholder={t('commercePaymentConfig-form-fiservQrConfigId-placeholder')}
          value={formik.values.fiservQrConfigId}
          options={fiservQrOptions}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.fiservQrConfigId}
          error={formik.errors.fiservQrConfigId}
          isClearable={true}
          backspaceRemovesValue={true}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
        <Box marginTop="1rem">
          <Button fullWidth variant="contained" color="primary" type="submit" disabled={formik.isSubmitting}>
            {t('venuePaymentConfig-form-saveConfig')}
          </Button>
        </Box>
      </form>
    </>
  );
};
