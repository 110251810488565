import React from 'react';
import { useFormik } from 'formik';
import { Box, Button, TextField, FormControlLabel, Switch } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import SelectField from 'components/SelectField';
import ConsumptionsReportFormProps from 'types/forms/ConsumptionsReportFormProps';
import 'styles/forms/ConsumptionsReportForm.scss';
import { AdminForm, EditorForm } from './schemas/ConsumptionsReportForm';
import AppContext from 'contexts/AppContext';
import { getAllVenues } from 'services/venuesServices';
import { getAvailableCloverPoints } from 'services/cloverServices';
import Venue from 'types/models/Venue';
import Commerce from 'types/models/Commerce';
import UserRoleType from 'types/enums/UserRoleType';
import CloverPoint from 'types/models/CloverPoint';
import { ReducerState } from 'types/models/ReducerAction';
import { cloverPointsReducer as reducer, initialStateForCloverPoints } from 'utils/report';
import { parseDateToFormik, parseDateTimeLumen } from 'utils/datetime';

export default ({ setCurrentFormData }: ConsumptionsReportFormProps): JSX.Element => {
  const { authData } = React.useContext(AppContext);
  const [venues, setVenues] = React.useState<Venue[] | null>(null);
  const [venueCommerces, setVenueCommerces] = React.useState<Commerce[] | null>(null);
  const [state, dispatch] = React.useReducer(reducer, initialStateForCloverPoints);
  const [selectedVenueTimeZone, setSelectedVenueTimeZone] = React.useState<string | null>(null);
  const { t } = useTranslation();

  React.useEffect(() => {
    const getVenues = async () => {
      const allVenues = await getAllVenues();
      setVenues(allVenues);
    };
    getVenues();
  }, []);

  const venuesForSelect = (venues: Venue[]) => {
    return venues.map((venue) => {
      return {
        value: venue.id,
        label: venue.name,
      };
    });
  };

  const commercesForSelect = (commerces: Commerce[]) => {
    return commerces.map((commerce) => {
      return {
        value: commerce.id,
        label: commerce.name,
      };
    });
  };

  const cloverPointsForSelect = (state: ReducerState[]) => {
    return state
      .reduce((acc, value) => {
        if (value.cloverPoints && value.cloverPoints.length > 0) {
          acc.push(value.cloverPoints);
        }
        return acc;
      }, [] as Array<CloverPoint[]>)
      .flatMap((cloverPoint) => cloverPoint)
      .map((cloverPoint) => {
        return {
          value: cloverPoint.id,
          label: `${cloverPoint.description} - Serial: ${cloverPoint.serial}`,
        };
      });
  };

  const formik = useFormik({
    initialValues: {
      venueId: '',
      commerceIds: [] as string[],
      dateFrom: '',
      dateTo: '',
      cloverPointIds: [] as string[],
      isCashPayment: false,
      isCardPayment: false,
      isFiservQrPayment: false,
    },
    validationSchema:
      authData?.user?.role === UserRoleType.Admin || authData?.user?.role === UserRoleType.VenueEditor
        ? AdminForm(t)
        : EditorForm(t),
    onSubmit: (values) => {
      setCurrentFormData({
        ...values,
        dateFrom: parseDateTimeLumen(moment(values.dateFrom).toDate(), ''),
        dateTo: parseDateTimeLumen(moment(values.dateTo).toDate(), ''),
      });
      formik.setSubmitting(false);
    },
  });

  const settingPossibleCloverPoints = React.useCallback(
    async (venueId?: string, commerceIds?: string[]) => {
      if (venueId) {
        const isVenueIdInCloverPointsState = state.find(
          (cloverPoint) => cloverPoint.entityType === 'venue' && cloverPoint.entityId === formik.values.venueId,
        );
        if (!isVenueIdInCloverPointsState) {
          dispatch({ type: 'cleanup-clover-points', operation: 'reset' });
          const correspondingVenue = venues?.find((venue) => venue.id === formik.values.venueId);
          if (correspondingVenue) {
            const cloverConfigId = correspondingVenue.paymentConfig?.cloverPointConfigId;
            if (cloverConfigId) {
              const cloverPoints = await getAvailableCloverPoints(cloverConfigId);
              dispatch({
                type: 'new-venue-clover-points',
                operation: 'add',
                payload: {
                  entityType: 'venue',
                  cloverPoints,
                  entityId: correspondingVenue.id,
                },
              });
            }
          }
        }

        const currentCommerceIdsForCloverPoints = state.reduce((acc, point) => {
          if (point.entityType === 'commerce') {
            acc.push(point.entityId);
          }
          return acc;
        }, [] as string[]);

        if (commerceIds) {
          for await (const commerceId of commerceIds) {
            if (!currentCommerceIdsForCloverPoints.includes(commerceId)) {
              const correspondingCommerce = venueCommerces?.find((commerce) => commerce.id === commerceId);
              if (correspondingCommerce) {
                const cloverConfigId = correspondingCommerce.paymentConfig?.cloverPointConfigId;
                if (cloverConfigId) {
                  const cloverPoints = await getAvailableCloverPoints(cloverConfigId);
                  dispatch({
                    type: 'new-commerce-clover-points',
                    operation: 'add',
                    payload: {
                      entityType: 'commerce',
                      cloverPoints,
                      entityId: correspondingCommerce.id,
                    },
                  });
                }
              }
            }
          }
          if (state.length > 0 && state[0]?.entityId !== initialStateForCloverPoints[0]?.entityId)
            dispatch({
              type: 'delete-commerce-clover-points-not-present-in-id-list',
              operation: 'delete',
              selectedIds: [venueId, ...commerceIds],
            });
        }
      }
    },
    [formik.values.venueId, formik.values.commerceIds],
  );

  React.useEffect(() => {
    const getSelectedVenue = (): Venue[] => {
      if (!venues || !formik.values.venueId) return [];
      return venues.filter((venue) => venue.id.includes(formik.values.venueId));
    };
    if (formik.values.cloverPointIds.length > 0) {
      formik.setFieldValue('cloverPointIds', []);
    }
    getSelectedVenue().map((element) => {
      if (!element.commerces) return [];
      setSelectedVenueTimeZone(element.timeZoneIdentifier);
      return setVenueCommerces(element.commerces);
    });
  }, [formik.values.venueId]);

  React.useEffect(() => {
    settingPossibleCloverPoints(formik.values.venueId, formik.values.commerceIds);
  }, [formik.values.venueId, formik.values.commerceIds]);

  return (
    <form onSubmit={formik.handleSubmit}>
      {venues && (
        <SelectField
          label={t('consumptionsReport-form-venueId-label')}
          name="venueId"
          placeholder={t('consumptionsReport-form-venueId-placeholder')}
          value={formik.values.venueId}
          options={venuesForSelect(venues)}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.venueId}
          error={formik.errors.venueId}
          isClearable={false}
          backspaceRemovesValue={false}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
      )}
      {(authData?.user?.role === UserRoleType.Admin ||
        authData?.user?.role === UserRoleType.VenueEditor ||
        authData?.user?.role === UserRoleType.FranchiseEditor) && (
        <SelectField
          label={t('consumptionsReport-form-commerceId-label')}
          name="commerceIds"
          placeholder={t('consumptionsReport-form-commerceId-placeholder')}
          value={formik.values.commerceIds}
          options={venueCommerces ? commercesForSelect(venueCommerces) : []}
          isMulti={true}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.commerceIds}
          error={formik.errors.commerceIds}
          isClearable={false}
          backspaceRemovesValue={false}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
      )}
      {state.length > 0 && state[0]?.entityId !== initialStateForCloverPoints[0]?.entityId && (
        <SelectField
          label={t('consumptionsReport-form-clover-points-label')}
          name="cloverPointIds"
          placeholder={t('consumptionsReport-form-clover-points-placeholder')}
          value={formik.values.cloverPointIds}
          options={state ? cloverPointsForSelect(state) : []}
          isMulti={true}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.cloverPointIds}
          error={formik.errors.cloverPointIds}
          isClearable={false}
          backspaceRemovesValue={false}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
      )}
      <div className="vertical-bottom-space">
        <FormControlLabel
          id="isCashPayment"
          name="isCashPayment"
          control={<Switch checked={formik.values.isCashPayment} onChange={formik.handleChange} />}
          label={t('consumptionsReport-form-clover-point-cash-order')}
          disabled={formik.isSubmitting}
        />
        <FormControlLabel
          id="isCardPayment"
          name="isCardPayment"
          control={<Switch checked={formik.values.isCardPayment} onChange={formik.handleChange} />}
          label={t('consumptionsReport-form-clover-point-card-order')}
          disabled={formik.isSubmitting}
        />
        <FormControlLabel
          id="isFiservQrPayment"
          name="isFiservQrPayment"
          control={<Switch checked={formik.values.isFiservQrPayment} onChange={formik.handleChange} />}
          label={t('consumptionsReport-form-clover-point-fiserv-qr-order')}
          disabled={formik.isSubmitting}
        />
      </div>
      <TextField
        margin="dense"
        fullWidth
        name="dateFrom"
        label={t('consumptionsReport-form-dateFrom')}
        value={
          formik.values.dateFrom
            ? parseDateToFormik(moment(formik.values.dateFrom).toDate(), '')
            : formik.values.dateFrom
        }
        onChange={(event) => {
          formik.setFieldValue('dateFrom', moment(moment(event.target.value).tz(selectedVenueTimeZone ?? '')).toDate());
        }}
        error={formik.touched.dateFrom && Boolean(formik.errors.dateFrom)}
        helperText={formik.touched.dateFrom && formik.errors.dateFrom}
        disabled={formik.isSubmitting}
        type="datetime-local"
        InputLabelProps={{
          shrink: true,
        }}
      />
      {formik.values.dateFrom && (
        <TextField
          margin="dense"
          fullWidth
          name="dateTo"
          label={t('consumptionsReport-form-dateTo')}
          value={
            formik.values.dateTo ? parseDateToFormik(moment(formik.values.dateTo).toDate(), '') : formik.values.dateTo
          }
          onChange={(event) => {
            formik.setFieldValue('dateTo', moment(moment(event.target.value).tz(selectedVenueTimeZone ?? '')).toDate());
          }}
          error={formik.touched.dateTo && Boolean(formik.errors.dateTo)}
          helperText={formik.touched.dateTo && formik.errors.dateTo}
          disabled={formik.isSubmitting}
          type="datetime-local"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      <Box marginTop="1rem">
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          disabled={!formik.isValid || formik.isSubmitting}
        >
          {t('consumptionsReport-form-search')}
        </Button>
      </Box>
    </form>
  );
};
