import React from 'react';
import { useFormik } from 'formik';
import { Box, Button, InputAdornment, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SelectField from 'components/SelectField';
import ProtocolOrdersFormProps, { DetailOrderProduct, OrderDetailItem } from 'types/forms/ProtocolOrdersFormProps';
import { AdminForm } from './schemas/ProtocolOrdersForm';
import { getVenuesCommercesProducts } from 'services/venuesServices';
import Venue from 'types/models/Venue';
import Commerce from 'types/models/Commerce';
import 'styles/forms/ConsumptionsReportForm.scss';
import { productWithoutStock } from 'utils/products';

export default ({
  setisOrderCreatedFalse,
  isOrderCreated,
  setCurrentFormData,
  setVenueAndCommerceSelected,
  getPaginatedOrders,
}: ProtocolOrdersFormProps): JSX.Element => {
  const [venues, setVenues] = React.useState<Venue[] | null>(null);
  const [venueCommerces, setVenueCommerces] = React.useState<Commerce[] | null | any>(null);
  const [selectedCommerce, setSelectedCommerce] = React.useState<Commerce | null>(null);
  const [commerceProducts, setCommerceProducts] = React.useState<DetailOrderProduct[] | null>(null);
  const [selectedProducts, setSelectedProducts] = React.useState<DetailOrderProduct[]>([]);
  const [orderDetail, setOrderDetail] = React.useState<OrderDetailItem[] | null>(null);
  const [totalAmount, setTotalAmount] = React.useState<number>(0);
  const [carPatent, setCarPatent] = React.useState<string | null>(null);
  const [email, setEmail] = React.useState<string | null>(null);
  const [incompleteForm, setIncompleteForm] = React.useState(false);
  const [selectedVenueTimeZone, setSelectedVenueTimeZone] = React.useState<string | null>(null);
  const { t } = useTranslation();

  const venuesForSelect = (venues: Venue[]) => {
    return venues.map((venue) => {
      return {
        value: venue.id,
        label: venue.name,
      };
    });
  };
  const commercesForSelect = (commerces: Commerce[]) => {
    return commerces.map((commerce) => {
      return {
        value: commerce.id,
        label: commerce.name,
      };
    });
  };
  const productsForSelect = (products: DetailOrderProduct[]) => {
    return products.map((product) => {
      return {
        value: product.id,
        label: `${product.name} ${
          productWithoutStock(product) ? `${t('general-suggestions.protocol-order-no-stock')}` : ''
        }`,
        disabled: productWithoutStock(product),
      };
    });
  };

  const formik = useFormik({
    initialValues: {
      venueId: '',
      commerceId: '',
      productsIds: [],
      patent: '',
      email: '',
    },
    validationSchema: AdminForm(t),

    onSubmit: (values) => {
      if (
        !orderDetail ||
        orderDetail.length === 0 ||
        !selectedCommerce ||
        orderDetail.some((el) => el.quantity === '')
      ) {
        formik.setSubmitting(false);
        setIncompleteForm(true);
        return;
      }
      formik.setSubmitting(true);
      setCurrentFormData({
        detail: orderDetail,
        commerceId: selectedCommerce.id,
        venueId: values.venueId,
        byProtocol: true,
        localTimeZone: selectedVenueTimeZone ?? '',
        delivery: carPatent ? `Patente: ${carPatent}` : null,
        guestEmail: email ? email : null,
        isCash: false,
        isForTotem: false,
      });
      formik.setSubmitting(false);
      getPaginatedOrders();
      setisOrderCreatedFalse();
    },
  });

  React.useEffect(() => {
    if (isOrderCreated) {
      setSelectedCommerce(null);
      setVenueCommerces(null);
      setSelectedProducts([]);
      setCommerceProducts(null);
      setCarPatent('');
      formik.setFieldValue('commerceId', '');
      formik.setFieldValue('productsIds', []);
      setIncompleteForm(false);
      formik.resetForm();
      getPaginatedOrders();
    }
  }, [isOrderCreated]);
  React.useEffect(() => {
    if (!selectedCommerce) return;
    setVenueAndCommerceSelected({
      commerce: selectedCommerce,
      venueId: formik.values?.venueId,
    });
    setOrderDetail(null);
    setSelectedProducts([]);
  }, [selectedCommerce, formik.values?.venueId]);

  const setProductDetail = (protocolOrderDetail: OrderDetailItem) => {
    setOrderDetail((prevOrderDetail) => {
      if (prevOrderDetail === null) {
        return [protocolOrderDetail];
      } else {
        const existingProductIndex = prevOrderDetail.findIndex(
          (item) => item.product.id === protocolOrderDetail.product.id,
        );
        if (existingProductIndex !== -1) {
          const updatedOrderDetail = [...prevOrderDetail];
          updatedOrderDetail[existingProductIndex] = protocolOrderDetail;
          return updatedOrderDetail;
        } else {
          return [...prevOrderDetail, protocolOrderDetail];
        }
      }
    });
    setIncompleteForm(false);
  };

  const getTotalAmountOrders = (): number => {
    if (!orderDetail) return 0;
    const totalAmountOrders = orderDetail.reduce((accumulator, item) => {
      const itemTotal = parseFloat(item.product.price) * parseInt(item.quantity);
      return accumulator + itemTotal;
    }, 0);
    return totalAmountOrders;
  };

  // Eliminar de orderDetail los productos que se eliminen del input
  React.useEffect(() => {
    if (!orderDetail) return;
    // Si es menor es porque se estan agregando. Eso esta controlado
    if (orderDetail.length < selectedProducts.length) return;
    const filteredOrderDetail = orderDetail.filter((orderItem) =>
      selectedProducts.some((selectedProduct) => selectedProduct.id === orderItem.product.id),
    );
    setOrderDetail(filteredOrderDetail);
  }, [selectedProducts]);

  React.useEffect(() => {
    setTotalAmount(getTotalAmountOrders());
  }, [orderDetail]);

  React.useEffect(() => {
    const getVenues = async () => {
      const allVenues = await getVenuesCommercesProducts();
      setVenues(allVenues);
    };
    getVenues();
  }, [isOrderCreated]);

  React.useEffect(() => {
    const getSelectedVenue = (): Venue[] => {
      if (!venues || !formik.values.venueId) return [];
      return venues.filter((venue) => venue.id.includes(formik.values.venueId));
    };
    getSelectedVenue().map((element) => {
      if (!element.commerces) return [];
      setSelectedVenueTimeZone(element.timeZoneIdentifier);
      return setVenueCommerces(element.commerces);
    });
    setIncompleteForm(false);
    setSelectedCommerce(null);
    formik.setFieldValue('commerceId', '');
    formik.setFieldValue('productsIds', []);
  }, [formik.values.venueId]);

  React.useEffect(() => {
    if (!venueCommerces) return;
    const selectedCommerce = venueCommerces.find((commerce: Commerce) => commerce.id === formik.values.commerceId);
    if (selectedCommerce) {
      setCommerceProducts(selectedCommerce.products);
      setSelectedCommerce(selectedCommerce);
    }
    formik.setFieldValue('productsIds', []);
    setIncompleteForm(false);
  }, [formik.values.commerceId]);

  React.useEffect(() => {
    if (!formik.values.productsIds || !commerceProducts) return;
    const productsIds: string[] = formik.values.productsIds;
    const selectedProducts = commerceProducts.filter((product) => productsIds.includes(product.id));
    setSelectedProducts(selectedProducts);
  }, [formik.values.productsIds]);

  return (
    <form onSubmit={formik.handleSubmit}>
      {venues && (
        <SelectField
          label={t('consumptionsReport-form-venueId-label')}
          name="venueId"
          placeholder={t('consumptionsReport-form-venueId-placeholder')}
          value={formik.values.venueId}
          options={venuesForSelect(venues)}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.venueId}
          error={formik.errors.venueId}
          isClearable={false}
          backspaceRemovesValue={false}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
      )}
      <SelectField
        label={t('consumptionsReport-form-commerceId-label')}
        name="commerceId"
        placeholder={t('consumptionsReport-form-commerceId-placeholder')}
        value={formik.values.commerceId}
        options={venueCommerces ? commercesForSelect(venueCommerces) : []}
        isMulti={false}
        setFieldValue={formik.setFieldValue}
        onBlur={formik.handleBlur}
        touched={formik.touched.commerceId}
        error={formik.errors.commerceId}
        isClearable={false}
        backspaceRemovesValue={false}
        isDisabled={formik.isSubmitting}
        isSearchable={true}
      />

      <SelectField
        label={t('protocolOrders-form-productsIds-label')}
        name="productsIds"
        placeholder={t('protocolOrders-form-productsIds-placeholder')}
        value={formik.values.productsIds}
        options={commerceProducts ? productsForSelect(commerceProducts) : []}
        isMulti={true}
        setFieldValue={formik.setFieldValue}
        onBlur={formik.handleBlur}
        touched={formik.touched.productsIds}
        error={formik.errors.productsIds}
        isClearable={false}
        backspaceRemovesValue={false}
        isDisabled={formik.isSubmitting}
        isSearchable={true}
      />
      {selectedProducts?.map((prod) => {
        return (
          <Box key={prod.id}>
            <QuantityInput product={prod} setProductDetail={setProductDetail} />
            {selectedCommerce?.type?.description === 'Parking' && (
              <TextField
                margin="dense"
                fullWidth
                id="patent"
                name="patent"
                label={t('protocolOrders-form-patent-label')}
                value={carPatent ?? ''}
                onChange={(v) => {
                  setCarPatent(v.currentTarget.value);
                }}
                disabled={formik.isSubmitting}
              />
            )}
          </Box>
        );
      })}
      {incompleteForm && (
        <Typography style={{ fontSize: 12, color: 'red' }}>
          {t('general-suggestions.protocol-order-incomplete')}
        </Typography>
      )}
      {totalAmount > 0 && <Typography style={{ fontSize: 14, marginTop: 10 }}>${totalAmount.toFixed(2)}</Typography>}
      <TextField
        margin="dense"
        fullWidth
        id="email"
        name="email"
        label={t('protocolOrders-form-email-label')}
        value={email ?? ''}
        onChange={(v) => {
          setEmail(v.currentTarget.value);
        }}
        disabled={formik.isSubmitting}
      />
      <Box marginTop="1rem">
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          disabled={!formik.isValid || formik.isSubmitting}
        >
          {t('protocolOrders-form-create')}
        </Button>
        <Box mt={0.5}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => {
              getPaginatedOrders();
            }}
            disabled={!formik.isValid || formik.isSubmitting}
          >
            {t('protocolOrders-form-search')}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

const QuantityInput = ({
  product,
  setProductDetail,
}: {
  product: DetailOrderProduct;
  setProductDetail: (order: OrderDetailItem) => void;
}) => {
  const [quantity, setQuantity] = React.useState('');
  React.useEffect(() => {
    setProductDetail({ product, quantity });
  }, [quantity]);

  return (
    <div>
      <TextField
        margin="dense"
        fullWidth
        id="stock"
        name="stock"
        label={product.name}
        onChange={(v) => {
          setQuantity(v.currentTarget.value);
        }}
        value={quantity}
        InputProps={{
          endAdornment: <InputAdornment position="end">{'Unidades'}</InputAdornment>,
        }}
      />
    </div>
  );
};
